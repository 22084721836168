.InfoWindow-base {
    height: 60px;
}

.base-logo {
    padding-top: 6px;
    float: left;
}

.base-logo img {
    object-fit: cover;
}

.base-name {
    padding-top: 6px;
    padding-left: 10px;
    float: left;
    font-size: 14px;
    font-weight: bold;
}

.base-id {
    font-size: 12px;
    font-weight: normal;
}

.map-paper {
    padding: 5px;
}

.map {
    border: solid 1px #cecece;
}

.sponsor-boxed {
    height: 14px;
    width: 22px;
    text-align: center;
    padding-top: 0px;
    border: 1px solid #c5c5c5;
    background-color: #5bff02;
    margin: 2px;
}

.sponsor-boxed-true {
    height: 14px;
    width: 22px;
    text-align: center;
    padding-top: 0px;
    border: 1px solid #c5c5c5;
    margin: 2px;
    background-color: #ff0202;
}

.sponsor ul {
    display:table;
    margin:0px auto 0px auto;
}

.sponsor ul li {
    display: inline-block;
    list-style: none;
}

.sponsor-container {
    margin-left: -26px;
    margin-right: 0px;
}

.InfoWindow-head {
    font-size: 14px;
    color: rgb(105, 105, 105);
    font-weight: bold;
    padding: 8px 0 2px;
}

.inline {
    display: inline;
}

.xls {
    font-size: 12px;
    color: #2b74a5;
    font-weight: bold;
    padding-right: 10px;
}

.xls p {
    font-weight: normal;
    color: #2f2f2f;
    display: inline;
}

.xls-container {
    position: absolute;
    bottom: 50px;
    background-color: #fff;
    margin-right: 68px;
    margin-left: 4px;
    padding: 10px;
    border: solid 1px #cecece;
}

.map-underline {
    border-bottom: solid 1px #c5c5c5;
    padding-top: 8px;
    margin-bottom: 8px;
}

.label {
    box-sizing:border-box;
  background: #05F24C;
  box-shadow: 2px 2px 4px #333;
  border:5px solid #346FF7;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  -webkit-animation: pulse 1s ease 1s 3;
  -moz-animation: pulse 1s ease 1s 3;
  animation: pulse 1s ease 1s 3;
}

.searchField {
    margin-bottom: 6px;
    margin-right: 14px;
    margin-top: 4px;
}

.map-legend {
    padding-top: 38px;
    margin: 4px;
    z-index: 0;
    position: absolute;
    right: 0px;
    top: 0px;
}

.legend-overlay {
    background-color: #fff;
    border: solid 1px rgb(194, 194, 194);
    padding: 0 10px;
}


.map-legend-off {
    display: none;
}

.legend-overlay img {
    vertical-align: middle;
}

.legend-overlay p {
    float: right;
    background-color: #f1f1f1;
    padding: 2px;
    border: solid 1px #e0e0e0;
}

.legend-overlay .underline {
    border-top: solid 1px #eaeaea;
}

.infoBox {
    float: right;
    background-color: #f1f1f1;
    padding: 2px;
    border: solid 1px #e0e0e0;
}

.high-one {
    margin-top: 15px;
}

.high-two {
    margin-top: 8px;
}

.high-three {
    margin-top: 2px;
}

.legend-btn {
    margin-top: -32px;
    position: fixed;
    right: 37px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    outline: none;
}

/*.map-route {
    background-color: #fff;
    border: solid 1px rgb(231, 231, 231);
    padding: 7px 6px;
    font-family: Roboto, Arial, sans-serif;
    user-select: none;
    font-size: 11px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}*/

.map-route-container-input {
    width: 100%;
}

.map-route-container-input-x {
    width: 88%;
}
.map-route-container-input-btn {
    width: 30px;
    height: 20px;
}

.map-route-container {
    padding-top: 80px;
    z-index: 0;
    position: absolute;
    left: 10px;
}

.map-route-container-x {
    background-color: #fff;
    border: solid 1px rgb(231, 231, 231);
    padding: 7px 6px;
    font-family: Roboto, Arial, sans-serif;
    user-select: none;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    width: 260px;
}

.map-route-container-btn {
    padding-top: 10px;
    z-index: 5;
    position: absolute;
    left: 106px;
}

.map-btn-top {
    margin-top: 8px !important;
}

.map-route-btn {
    background-color: #fff !important;
    min-height: 28px !important;
    height: 0px !important;
    border-radius: 2px !important;
    line-height: 0.5em !important;
    padding: 0 10px !important;
    font-size: 11px !important;
    color: rgb(0, 0, 0) !important;
    background-clip: padding-box;
    font-family: Roboto, Arial, sans-serif !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
    text-transform: none !important;
}

.routeBtn {
    height: 0px !important;
    min-height: 30px !important;
    line-height: none !important;
}

.routeTime {
    padding: 18px 0 6px;
    font-size: 14px;
    color: rgb(154, 154, 154);
}


.routeKm {
    font-size: 14px;
    color: rgb(154, 154, 154);
}