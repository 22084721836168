.userContainer {
    display: flex;
    flex-direction: row;
    padding: 12px 0 8px;
    border-bottom: 1px solid #444;
    /* background: #121212b8; */
    cursor: pointer;
}

.userContainer .userThumb {
    width: 64px;
    text-align: center;
}

.userContainer .userThumb .thump {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.userContainer .userInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.userContainer .userInfo .arrow-size {
    align-self: center;
    font-size: 28px;
}

.userContainer .userInfo .userName {
    font-weight: bold;
}

.userContainer .userInfo .userRoles {
    font-size: 12px;
    color: #444444;
    margin-top: 6px;
}
.userContainer .userInfo .userId {
    font-size: 12px;
    color: #444444;
}

.userContainer .thumb.mbsc-ic {
    font-size: 3em;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    left: 5px;
}

.userContainer.newUser .userInfo .userName {
    position: relative;
    top: 19px;
}

.userThumb svg {
    font-size: 57px;
}