.dashboard-checkbar {
    border: 1px solid #929292;
    height: 36px;
    width: 100%;
}

.checkbar-container {
    float: left;
    height: 100%;
    text-align: center;
    line-height: 36px;
}

.cameraChart {
    height: 250px;
}

.errorBox {
    max-height: 300px;
    overflow: scroll;
}

.errorBox::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.errorBox::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.errorBox::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.errorBox::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.errorBox::-webkit-scrollbar-thumb:active {
    background: #707070;
}

.errorBox::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.errorBox::-webkit-scrollbar-corner {
    background: transparent;
}

.errorBox-number {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
}

.errorBox-list {
    padding: 10px;
    background-color: #b41f1f2b;
    cursor: pointer;
    margin: 2px;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.errorBox-list:hover {
    background-color: #b41f1f5d;
}

.errorBox-list:active {
    background-color: #b41f1f28;
}

.errorBox-list-fail {
    background-color: #ff0000a1 !important;
}

.list-sw {
    flex-direction: row;
}

.list-ok {
    flex-direction: row;
}

.layout-container-setting {
    padding: 20px;
}

.searchBox-number {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
}

.searchBox-btn-activ {
    color: #00ff00;
}

.searchBox-btn-inactiv {
    color: #ff0000c7;
}

.searchBox-leg {
    margin: 6px 6px 2px;
    text-align: center;
}

.settings-headingText {
    color: #757575;
    font-size: 13px;
    margin-bottom: -6px;
    margin-top: 8px;
}

.settings-headingText-select {
    color: #757575;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 8px;
}

.searchBox-numberSearch {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBox-leg-clubname {
    padding: 6px 0;
    margin: 0px 2px 2px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #151315;
}

.dash-notes-heading {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.dash-notes-heading:hover {
    background-color: #9e9e9e5d;
}

.dash-notes-heading:focus {
    background-color: #9e9e9e5d;
}

.dash-notes-headingtext {
    line-height: 36px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dash-notes-heading-btnX {
    color: #FF3517;
    padding: 10px 20px 10px 0px;
    font-weight: bold;
}

.dash-notes-heading-btn span {
    font-weight: bold;
}

.dash-note-container-head {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 2px;
    padding: 10px 0;
    color: #fff;
    background-color: #a47979;
    margin: 0 2px;
}

.dash-note-container-body {
    display: flex;
    justify-content: flex-start;
    background-color: #f2d9d9;
    padding: 4px 10px;
    margin-bottom: 2px;
    margin: 2px 2px;
}

.dash-note-container-body:hover {
    background-color: #b41f1f5d;
}

.dash-note-container {
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}

.dash-note-container::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.dash-note-container::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.dash-note-container::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.dash-note-container::-webkit-scrollbar-thumb:hover {
    background: #949494;
}

.dash-note-container::-webkit-scrollbar-thumb:active {
    background: #707070;
}

.dash-note-container::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.dash-note-container::-webkit-scrollbar-corner {
    background: transparent;
}

.ExpansionPanelSummary svg {
    color: #FF3517;
}

.cameraChart-graph-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    padding: 0 10px 10px;
}


.slick-next {
    right: 38px !important;
}

.slick-prev {
    left: 18px !important;
    z-index: 2;
}

.slick-prev:before, .slick-next:before {
    color: #FF3517 !important;
    font-size: 40px !important;
}

.errorBox-lost thead th {
    color: rgb(121, 121, 121) !important;
    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;
}

.errorBox-lost tr {
    font-family: sans-serif;
}

.outer-div-number {
    text-align: center;
    margin-top: 10px;

}

.inner-div-number {
    display: inline-block;
    margin: 0px auto;
    top: 50%;
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    color: #707070;
}