.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-head-video {
    display: grid;
    align-items: center;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.body-infoline {
    padding: 3px;
}

.layout-body a {
    color: #FF3517;
}

.layout-body-video {
    padding: 4px;
    padding-bottom: 0px;
}

.layout-body-video a {
    z-index: 0 !important;
    position: unset !important;
}

.layout-table {
    height: 300px;
    overflow-y: auto;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.test {
    width: 200px;
    height: 500px;
}

.game-btn {
    width: 50%;
}

.game-btn-full {
    width: 100%;
}

.game-btn span {
    font-weight: bold;
    color: #fff;
}

.game-btn-full span {
    font-weight: bold;
    color: #fff;
}

.game-addtest {
    padding: 20px;
}

.addtest-head {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.addtest-headpadding {
    padding: 20px 0 0px;
    color: rgba(0, 0, 0, 0.54);
}

.headpadding-ceck {
    padding: 0 0 0 20px;
}

.Select-control {
    border-color: #fff !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.Select-control:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.623) !important;
    
}

.Select-value {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Select-placeholder {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.addtest-textfield {
    width: 100%;
}

.addtest-btn {
    margin-top: 40px !important;
    margin-right: 20px !important;
}

.flowplayerContainer {
    max-height: 293px !important;
}

.layout-table::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.layout-table::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.layout-table::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.layout-table::-webkit-scrollbar-thumb:active {
  background: #707070;
}
.layout-table::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-corner {
  background: transparent;
}

.infoline-error {
    color: red;
}

.chips-testadd {
    display: flex;
    flex-wrap: wrap;
}

.formControl-testadd {
    width: 100%;
}

.no-refund {
    padding: 6px 10px;
    background: #82e28f;
    color: #696969 !important;
    border: none;
    text-align-last: center;
}

.btn-refund {
    padding: 6px 10px;
    background: #e28282;
    color: #fff !important;
    cursor: pointer;
    border: none;
    width: 100%;
}

.btn-refund:hover {
    background: #ce6868;
}


.package-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: row;
    border-top: 1px solid #a1a1a1;
    font-weight: bold;
    color: #FF3517;
}

.package-menu-icon {
    font-size: 14px;
    padding: 2px 26px;
    margin: 4px 0 -2px;
    cursor: pointer;
}

.package-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.packages-container {
    cursor: pointer;
    background: #e5e5e5;
    padding:  6px 10px;
    min-width: 200px;
    max-width: 400px;
    flex: 1 0 0;
    margin: 2px;
}

.pack-box {
    display: flex;
    padding: 6px 0;
    flex-direction: column;
    align-content: flex-start;
}

.pack-title {
    font-size: 12px;
    color: #707070;
}

.pack-text {
    font-size: 14px;
}


.buttwrapper-packages {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
}

.CSVImporter_ColumnDragSourceArea__box {
    width: 100%
}

.CSVImporter_ColumnDragSourceArea__page {
    overflow-y: auto;
}

.CSVImporter_FileSelector {
    padding: 4px;
    border: 2px dashed #000;
}


.infoText-finalCheck {
    padding: 12px 14px;
    background: #e3e3e3;
    color: #5c5c5c;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 40px;
    border: 1px solid #c3c3c3;
}

.clubX .textField-table-dropdown {
    max-width: 100%;
}