.telemetry-container {
    padding: 10px;
}

.infobox-dot {
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    width: 14px;
    margin: 2px;
}

.dot-ok {
    background-color: rgba(88, 199, 55, 0.112);
    border: 2px solid rgb(88, 199, 55);
}

.dot-ok-fill {
    background-color: rgba(88, 199, 55, 0.712);
    border: 2px solid rgb(88, 199, 55);
}

.dot-middle {
    background-color: rgba(199, 177, 55, 0.112);
    border: 2px solid rgb(199, 177, 55);
}

.dot-middle-fill {
    background-color: rgba(199, 177, 55, 0.712);
    border: 2px solid rgb(199, 177, 55);
}

.dot-bad {
    background-color: rgba(199, 55, 55, 0.112);
    border: 2px solid rgb(199, 55, 55);
}

.dot-bad-fill {
    background-color: rgba(199, 55, 55, 0.712);
    border: 2px solid rgb(199, 55, 55);
}

.infobox-table td {
    vertical-align: middle;
    font-size: 0.875rem;
    font-weight: 400;
}

.tb-padding {
    height: 25px;
}

.infobox-table .table-state {
    padding-left: 12px;
}

.table-text {
    width: 116px;
}

.tele-graph-timedate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: #000000;
    color: #fff;
}

.forSeriesBtn {
    display: flex;
    /*align-items: end;*/
    flex-flow: wrap;
    justify-content: flex-end;
    padding-right: 10px;
    padding-bottom: 8px;
}

.seriesBtn {
    cursor: pointer;
    color: rgb(0, 0, 0) !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
}

.checkupBtn {
    width: 100%;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff !important;
    background-color: #FF3517;
    padding: 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.checkupBtn:hover {
    background-color: #0099ff;
}

.telemetryValues {
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    background-color: #eaeaea;
    padding-bottom: 6px;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
}

.telemetryValues-tb {
    padding: 8px 0 0 10px;
    white-space: nowrap;
    color: rgb(61, 61, 61);
}

.telemetryValues-sp {
    background-color: #ffffff;
    color: #000;
    border-radius: 25px;
    padding: 2px 12px;
}

.telemetryValues-overlay {
    position: fixed;
    left: 69px;
    right: 6px;
    top: 69px;
    -webkit-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.75);
}

.tablerow-match {
    background-color: rgb(215, 249, 205);
}

.tablerow-match:hover {
    background-color: rgb(215, 249, 205, 0.6) !important;
}

.teleInfoContainerBtn {
    width: 100%;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff !important;
    background-color: #FF3517;
    padding: 10px;
    border-radius: 4px;
    text-transform: uppercase;
}

.teleInfoContainerBtn:hover {
    background-color: #0099ff;
}

.btn-cont .react-datepicker-wrapper {
    width: 112px !important;
    margin-right: 38px !important;
}

.tele-more-btn {
    background-color: #03abfc;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 24px;
}

.tele-more-btn:hover {
    background-color: #008acf;
}