html {
    overflow-x: hidden;
}

.headingText {
    padding: 0 0 14px;
    font-size: 20px;
}

.headingText a {
    color: #FF3517;
}

.moduleBackground {
    background-color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loading {
    text-align: center;
    padding: 100px 0;
}

.textField-table {
    width: 100%;
}

.textField-table-err {
    width: 100%;
    background-color: #ff000029;
}

.textField-label {
    font-size: 12px;
    color: #737373;
    
    .tooltip-icon {
        float: right;
        background-color: #f3f3f3;
        padding: 1px 6px;
        border-radius: 100%;
        color: #a7a7a7;
    }
}

.dialogtableedit-team-head {
    padding: 20px 0px;
    text-align: center;
}

.tableColumnVisibility {
    float: left;
    padding-left: 30px;
    margin: -10px;
    z-index:10
}

.overlaycheck-heading {
    padding-top: 25px;
}

.loggedSite {
    button {
        color: #FF3517;
    }
}

.textField-table-dropdowns {
    width: 100%;
}

.paper-default {
    padding: 20px;
}

.paper-body {
    word-wrap: break-word;
    text-align: center;
}

.layout-body-n {
    padding: 10px;
    word-wrap:break-word;
}

.layout-body-warning {
    background-color: #ff9494;
    color: #5f5f5f;
    font-weight: bold;
}

.layout-body-warning button {
    margin: -9px;
    float: right;
    color: #fff !important;
}

.toolbar-table {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 46px;
    margin: 1px 0;
    padding: 4px 12px;
}

.toolbar-btn-right {
    float: right;
}

.toolbar-btn-left {
    float: left;
}

.btn-reload {
    float: left;
    padding-top: 6px; 
}

.btn-reload button {
    min-width: 0px;
}

.btn-reload button:hover {
    text-decoration: none;
    background-color: #fff
}



.scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.scrollbar::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #949494;
}

.scrollbar::-webkit-scrollbar-thumb:active {
    background: #707070;
}

.scrollbar::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-corner {
    background: transparent;
}

.tbla th {
    padding-left: 14px;
}

.global-btn {
    color: #fff !important;
    font-weight: bold !important;
    width: 100%;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

.rotateItem {
    animation: rotation 1s infinite linear;
}

.uploadImg-prevImg-size {
    position: absolute;
    bottom: 10px;
    padding-left: 3px;
    width: 100%;
    font-size: 12px;
    color: #000000a8;
}

.uploadImg-prevImg {
    padding: 5px;
    border: solid 1px rgb(226, 226, 226);
    position: relative;
}

.videoUpload-prevText {
    text-align: center;
    height: 50px;
    vertical-align: middle;
    line-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.uploadImg-prevImg-tri {
    width: 0;
    height: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border-style: solid;
    border-width: 30px 0 0 1px;
    border-color: transparent transparent transparent #FF3517;
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    transition: 0.2s;
}

.uploadImg-prevImg-tri-red {
    border-color: transparent transparent transparent #FF3517 !important;
}

.uploadImg-prevImg-tri-right {
    width: 0;
    height: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border-style: solid;
    border-width: 30px 0 0 1px;
    border-color: transparent transparent transparent #FF3517;
    position: absolute;
    bottom: -1px;
    margin-right: 0;
    right: 0;
    transition: 0.2s;
}

.uploadImg-prevImg-tri-right-red {
    border-color: transparent transparent transparent #ff8080 !important;
}

.uploadImg-prevImg-tri-center {
    position: absolute;
    bottom: 3px;
    left: 9px;
}

.uploadImg-imgUploadWrapper {
    height: 32px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: #FF3517;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-weight: bold;
    border-radius: 0px 0px 6px 6px;
    text-transform: uppercase;
    font-size: 14px;
    transition: 0.2s;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.uploadImg-imgUploadWrapper.red {
    background-color: #FD3D00;
}

.uploadImg-fileInput-error {
    height: 28px;
    text-align: center;
    padding-top: 10px;
    margin: 0px;
    color: #6b6b6b;
    border: 1px solid #da9595;
    margin-top: 6px;
    background-color: #ff80808c !important;
}

.uploadImg-fileInput-warning {
    background-color: #ff8080  !important;
}

.uploadImg-imgUploadWrapper:hover {
    background-color: #006ca0;
}
.uploadImg-fileInput {
    cursor: pointer;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    z-index: 99;
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    font-size:50px;
    /*Opacity settings for all browsers*/
    opacity: 0;
    -moz-opacity: 0;
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}

.deleteImg-fileInput {
    cursor: pointer;
    height: 100%;
    position:absolute;
    top: 50px;
    right: 0;
    z-index: 99;
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    font-size:50px;
    /*Opacity settings for all browsers*/
    opacity: 0;
    -moz-opacity: 0;
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}



.dialogLoading-wait {
    position: absolute;
    width: 100%;
    top: 45%;
    text-align: center;
    z-index: 51;
    color: #FF3517;
}

.dialogLoading-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000073;
    z-index: 50;
}

.table-datepicker {
    padding: 4px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #949494;
}

.overlay-datepicker {
    background: transparent;
    width: 100%;
    outline: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 1001 !important;
}

.uploadBar-container {
    background-color: rgb(230, 230, 230);
    height: 26px;
    width: 100%;
    padding-top: 8px;
    margin-top: 14px;
    text-align: center;
    -webkit-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.75);
    z-index: 20000;
}

.uploadBar-inner {
    max-width: 99%;
    height: 100%;
    background-color: #FF3517c4;
    position: relative;
    top: -22px;
    left: 1px;
    z-index: 21000;
}

.loading-overlay-block {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00000042;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    border: 1px solid rgb(202, 173, 173);
    background-color: rgb(255, 180, 180);
    border-radius: 4px;
    margin: 20px 0;
}

.needsVerification-btn {
    color: #FF3517c4;
    font-size: 14px;
    font-weight: bold;
    background-color: #dff02b30;
    padding: 25px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
}

.needsVerification-btn:hover {
    color: #FF3517c4;
    background-color: #4df02b60;
}