html {
  overflow-x: hidden;
}

.headingText {
  padding: 0 0 14px;
  font-size: 20px;
}

.headingText a {
  color: #FF3517;
}

.moduleBackground {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.loading {
  text-align: center;
  padding: 100px 0;
}

.textField-table {
  width: 100%;
}

.textField-table-err {
  width: 100%;
  background-color: rgba(255, 0, 0, 0.1607843137);
}

.textField-label {
  font-size: 12px;
  color: #737373;
}
.textField-label .tooltip-icon {
  float: right;
  background-color: #f3f3f3;
  padding: 1px 6px;
  border-radius: 100%;
  color: #a7a7a7;
}

.dialogtableedit-team-head {
  padding: 20px 0px;
  text-align: center;
}

.tableColumnVisibility {
  float: left;
  padding-left: 30px;
  margin: -10px;
  z-index: 10;
}

.overlaycheck-heading {
  padding-top: 25px;
}

.loggedSite button {
  color: #FF3517;
}

.textField-table-dropdowns {
  width: 100%;
}

.paper-default {
  padding: 20px;
}

.paper-body {
  word-wrap: break-word;
  text-align: center;
}

.layout-body-n {
  padding: 10px;
  word-wrap: break-word;
}

.layout-body-warning {
  background-color: #ff9494;
  color: #5f5f5f;
  font-weight: bold;
}

.layout-body-warning button {
  margin: -9px;
  float: right;
  color: #fff !important;
}

.toolbar-table {
  border-bottom: 1px solid rgb(224, 224, 224);
  height: 46px;
  margin: 1px 0;
  padding: 4px 12px;
}

.toolbar-btn-right {
  float: right;
}

.toolbar-btn-left {
  float: left;
}

.btn-reload {
  float: left;
  padding-top: 6px;
}

.btn-reload button {
  min-width: 0px;
}

.btn-reload button:hover {
  text-decoration: none;
  background-color: #fff;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.scrollbar::-webkit-scrollbar-thumb:active {
  background: #707070;
}

.scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.tbla th {
  padding-left: 14px;
}

.global-btn {
  color: #fff !important;
  font-weight: bold !important;
  width: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotateItem {
  animation: rotation 1s infinite linear;
}

.uploadImg-prevImg-size {
  position: absolute;
  bottom: 10px;
  padding-left: 3px;
  width: 100%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6588235294);
}

.uploadImg-prevImg {
  padding: 5px;
  border: solid 1px rgb(226, 226, 226);
  position: relative;
}

.videoUpload-prevText {
  text-align: center;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uploadImg-prevImg-tri {
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-style: solid;
  border-width: 30px 0 0 1px;
  border-color: transparent transparent transparent #FF3517;
  position: absolute;
  bottom: -1px;
  margin-left: -5px;
  transition: 0.2s;
}

.uploadImg-prevImg-tri-red {
  border-color: transparent transparent transparent #FF3517 !important;
}

.uploadImg-prevImg-tri-right {
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-style: solid;
  border-width: 30px 0 0 1px;
  border-color: transparent transparent transparent #FF3517;
  position: absolute;
  bottom: -1px;
  margin-right: 0;
  right: 0;
  transition: 0.2s;
}

.uploadImg-prevImg-tri-right-red {
  border-color: transparent transparent transparent #ff8080 !important;
}

.uploadImg-prevImg-tri-center {
  position: absolute;
  bottom: 3px;
  left: 9px;
}

.uploadImg-imgUploadWrapper {
  height: 32px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #FF3517;
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  border-radius: 0px 0px 6px 6px;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.2s;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.uploadImg-imgUploadWrapper.red {
  background-color: #FD3D00;
}

.uploadImg-fileInput-error {
  height: 28px;
  text-align: center;
  padding-top: 10px;
  margin: 0px;
  color: #6b6b6b;
  border: 1px solid #da9595;
  margin-top: 6px;
  background-color: rgba(255, 128, 128, 0.5490196078) !important;
}

.uploadImg-fileInput-warning {
  background-color: #ff8080 !important;
}

.uploadImg-imgUploadWrapper:hover {
  background-color: #006ca0;
}

.uploadImg-fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  /*This makes the button huge. If you want a bigger button, increase the font size*/
  font-size: 50px;
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.deleteImg-fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 99;
  /*This makes the button huge. If you want a bigger button, increase the font size*/
  font-size: 50px;
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.dialogLoading-wait {
  position: absolute;
  width: 100%;
  top: 45%;
  text-align: center;
  z-index: 51;
  color: #FF3517;
}

.dialogLoading-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4509803922);
  z-index: 50;
}

.table-datepicker {
  padding: 4px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #949494;
}

.overlay-datepicker {
  background: transparent;
  width: 100%;
  outline: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 1001 !important;
}

.uploadBar-container {
  background-color: rgb(230, 230, 230);
  height: 26px;
  width: 100%;
  padding-top: 8px;
  margin-top: 14px;
  text-align: center;
  -webkit-box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  z-index: 20000;
}

.uploadBar-inner {
  max-width: 99%;
  height: 100%;
  background-color: rgba(255, 53, 23, 0.768627451);
  position: relative;
  top: -22px;
  left: 1px;
  z-index: 21000;
}

.loading-overlay-block {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.2588235294);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 1px solid rgb(202, 173, 173);
  background-color: rgb(255, 180, 180);
  border-radius: 4px;
  margin: 20px 0;
}

.needsVerification-btn {
  color: rgba(255, 53, 23, 0.768627451);
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(223, 240, 43, 0.1882352941);
  padding: 25px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}

.needsVerification-btn:hover {
  color: rgba(255, 53, 23, 0.768627451);
  background-color: rgba(77, 240, 43, 0.3764705882);
}
.dashboard-checkbar {
    border: 1px solid #929292;
    height: 36px;
    width: 100%;
}

.checkbar-container {
    float: left;
    height: 100%;
    text-align: center;
    line-height: 36px;
}

.cameraChart {
    height: 250px;
}

.errorBox {
    max-height: 300px;
    overflow: scroll;
}

.errorBox::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.errorBox::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.errorBox::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.errorBox::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.errorBox::-webkit-scrollbar-thumb:active {
    background: #707070;
}

.errorBox::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.errorBox::-webkit-scrollbar-corner {
    background: transparent;
}

.errorBox-number {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
}

.errorBox-list {
    padding: 10px;
    background-color: #b41f1f2b;
    cursor: pointer;
    margin: 2px;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.errorBox-list:hover {
    background-color: #b41f1f5d;
}

.errorBox-list:active {
    background-color: #b41f1f28;
}

.errorBox-list-fail {
    background-color: #ff0000a1 !important;
}

.list-sw {
    flex-direction: row;
}

.list-ok {
    flex-direction: row;
}

.layout-container-setting {
    padding: 20px;
}

.searchBox-number {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
}

.searchBox-btn-activ {
    color: #00ff00;
}

.searchBox-btn-inactiv {
    color: #ff0000c7;
}

.searchBox-leg {
    margin: 6px 6px 2px;
    text-align: center;
}

.settings-headingText {
    color: #757575;
    font-size: 13px;
    margin-bottom: -6px;
    margin-top: 8px;
}

.settings-headingText-select {
    color: #757575;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 8px;
}

.searchBox-numberSearch {
    flex-direction: row;
    padding: 10px;
    background-color: #a47979;
    color: #fff;
    margin: 2px 0 2px 2px;
    width: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBox-leg-clubname {
    padding: 6px 0;
    margin: 0px 2px 2px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #151315;
}

.dash-notes-heading {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.dash-notes-heading:hover {
    background-color: #9e9e9e5d;
}

.dash-notes-heading:focus {
    background-color: #9e9e9e5d;
}

.dash-notes-headingtext {
    line-height: 36px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dash-notes-heading-btnX {
    color: #FF3517;
    padding: 10px 20px 10px 0px;
    font-weight: bold;
}

.dash-notes-heading-btn span {
    font-weight: bold;
}

.dash-note-container-head {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 2px;
    padding: 10px 0;
    color: #fff;
    background-color: #a47979;
    margin: 0 2px;
}

.dash-note-container-body {
    display: flex;
    justify-content: flex-start;
    background-color: #f2d9d9;
    padding: 4px 10px;
    margin-bottom: 2px;
    margin: 2px 2px;
}

.dash-note-container-body:hover {
    background-color: #b41f1f5d;
}

.dash-note-container {
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}

.dash-note-container::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.dash-note-container::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.dash-note-container::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.dash-note-container::-webkit-scrollbar-thumb:hover {
    background: #949494;
}

.dash-note-container::-webkit-scrollbar-thumb:active {
    background: #707070;
}

.dash-note-container::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

.dash-note-container::-webkit-scrollbar-corner {
    background: transparent;
}

.ExpansionPanelSummary svg {
    color: #FF3517;
}

.cameraChart-graph-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    padding: 0 10px 10px;
}


.slick-next {
    right: 38px !important;
}

.slick-prev {
    left: 18px !important;
    z-index: 2;
}

.slick-prev:before, .slick-next:before {
    color: #FF3517 !important;
    font-size: 40px !important;
}

.errorBox-lost thead th {
    color: rgb(121, 121, 121) !important;
    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;
}

.errorBox-lost tr {
    font-family: sans-serif;
}

.outer-div-number {
    text-align: center;
    margin-top: 10px;

}

.inner-div-number {
    display: inline-block;
    margin: 0px auto;
    top: 50%;
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    color: #707070;
}
/* MyReactReader.css */
.fullsize-widget {
    width: 100%; /* Setzt die Breite auf 100% des Elternelements */
    height: 100vh; /* Setzt die Höhe auf 100% des Viewport */
    display: flex; /* Nutzt Flexbox für die Zentrierung des Kind-Elements */
    justify-content: center; /* Zentriert horizontal */
    align-items: center; /* Zentriert vertikal */
  }
  /* MyReactReader.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  padding-top: 50px; /* Abstand von der oberen Kante */
}

.search-input {
  width: 80%;
  max-width: 600px;
  padding: 10px 20px;
  margin-bottom: 30px; /* Abstand zwischen Suchfeld und Query-Boxen */
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  outline: none;
}

.search-input:-ms-input-placeholder {
  color: #aaa;
}

.search-input::placeholder {
  color: #aaa;
}

.search-input:focus {
  border-color: #5b9bd5;
}

.query-examples {
  width: 80%;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Erstellt zwei Spalten */
  grid-gap: 20px; /* Abstand zwischen den Boxen */
  margin-bottom: 20px; /* Abstand zum unteren Rand */
  position: relative; /* Erlaubt absolute Positionierung für das p-Element */
  padding-top: 30px; /* Platz für den Hinweistext */
  padding-bottom: 30px; /* Platz für den Hinweistext */
}

.query-button {
  padding: 15px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
  color: #333 !important;
  text-align: left;
}


.validation-notice {
  width: 207%; /* Breite des Hinweises */
  text-align: center; /* Zentrierter Text */
  font-size: 0.9em; /* Schriftgröße des Hinweises */
  color: #6c757d; /* Farbe des Hinweises */
  padding: 10px 0; /* Padding oben und unten */
  background-color: #e9ecef; /* Hintergrundfarbe des Hinweises */
  border-radius: 15px; /* Abgerundete Ecken */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Schatten für Tiefe */
  margin-top: 20px; /* Abstand zwischen den Boxen und dem Hinweistext */
}


.query-button:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

/* Für Mobilgeräte könnten Anpassungen nötig sein */
@media (max-width: 768px) {
  .query-examples {
    grid-template-columns: 1fr; /* Stellt sicher, dass auf kleinen Bildschirmen alles in einer Spalte ist */
  }
  .validation-notice {
    width: 100%; /* Breite des Hinweises */
  }
}

  .grecaptcha-badge { visibility: hidden; }
.colorBar {
  display: flex;
}
.colorBar .colorBox {
  height: 28px;
  width: 28px;
  margin: 4px;
  border: 1px solid #949494;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}

.cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
  background: #636363;
  color: #fff;
  border-radius: 4px 4px 0 0;
  box-shadow: rgba(0, 0, 0, 0.1490196078) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1490196078) 0px 8px 16px;
  width: 200px;
}

.swatch {
  display: inline-block;
  cursor: pointer;
}
.swatch .colorBox {
  height: 28px;
  width: 28px;
  margin: 4px;
  border: 1px solid #949494;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}
.valid-input {
  display: flex;
  flex-direction: row;
}

.valid-content {
  display: flex;
  flex-wrap: wrap;
}
.valid-content .content-itm {
  background: #cecece;
  margin: 4px;
  padding: 2px 6px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.valid-content .content-itm .itm-content .content-head {
  font-size: 10px;
}
.valid-content .content-itm .itm-content .content-text {
  font-size: 14px;
}
.valid-content .content-itm .itm-delete {
  padding: 2px 7px;
  margin: 0 0px 0 12px;
  background: #d48787;
  border-radius: 32px;
  color: #fff;
  cursor: pointer;
}
label.smol {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
}
.cityOverlay {
  width: 100%;
}
.cityOverlay div {
  min-width: 100%;
}

.cityOverlay-content div {
  min-width: 100%;
}
.cityOverlay-content .content-createCity {
  margin-top: 16px;
  background: #d5d5d5;
  border-radius: 5px;
  overflow: hidden;
  height: 320px;
  overflow-y: scroll;
}
.cityOverlay-content .content-createCity .createCity-input {
  margin: 16px 4px;
}
.cityOverlay-content .content-searchBox {
  margin-top: 16px;
  background: #d5d5d5;
  border-radius: 5px;
  overflow: hidden;
  height: 300px;
  overflow-y: scroll;
}
.cityOverlay-content .content-searchBox .searchBox-title {
  padding: 10px;
}
.cityOverlay-content .content-searchBox .searchBox-city {
  margin: 10px;
  background: whitesmoke;
  padding: 13px;
  cursor: pointer;
}
.cityOverlay-content .content-searchBox .searchBox-city:hover {
  background: rgb(231, 231, 231);
}
.cityOverlay-content .content-searchBox .searchBox-createCity {
  background: #5c5c5c;
  color: #ebebeb;
}
.cityOverlay-content .content-searchBox .searchBox-createCity:hover {
  background: #3d3d3d;
  color: #ebebeb;
}
.cityOverlay-content .content-searchBox .searchBox-notFoundCity {
  text-align-last: center;
  padding: 40px 0 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.s-spinner {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

.s-spinner.inline{
  position: relative;
  width: 32px;
  height: 32px;
  left: 10px;
  top: 0;
  margin: 0;
}

@keyframes spinner-bottom {
  0% {
    left: 24px;
  } 10% {
    left: 0;
  } 60% {
    right: 8px;
  } 70% {
    right: 32px;
    left: 0;
  } 100% {
    right: 32px;
    left: 0;
  }
}

@keyframes spinner-left {
  10% {
    top: 32px;
  } 20% {
    top: 8px;
  } 70% {
    bottom: 0;
  } 80% {
    bottom: 24px;
    top: 8px;
  } 100% {
    bottom: 24px;
    top: 8px;
  }
}

@keyframes spinner-top {
  20% {
    right: 24px;
  } 30% {
    right: 0;
  } 80% {
    left: 8px;
  } 90% {
    left: 32px;
    right: 0;
  } 100% {
    left: 32px;
    right: 0;
  }
}

@keyframes spinner-right {
  30% {
    bottom: 32px;
  } 40% {
    bottom: 8px;
  } 90% {
    top: 0;
  } 100% {
    top: 24px;
    bottom: 8px;
  }
}

.s-spinner__left:before,
.s-spinner__left:after,
.s-spinner__right:before,
.s-spinner__right:after {
  content: "";
  position: absolute;
  background-color: #FF3517;
}

.s-spinner__left:before {
  right: 8px;
  bottom: 0;
  left: 0;
  height: 8px;
  animation: spinner-bottom 1.5s linear infinite;
}

.s-spinner__left:after {
  top: 32px;
  bottom: 0;
  left: 0;
  width: 8px;
  animation: spinner-left 1.5s linear infinite;
}

.s-spinner__right:before {
  top: 0;
  right: 24px;
  left: 8px;
  height: 8px;
  animation: spinner-top 1.5s linear infinite;
}

.s-spinner__right:after {
  top: 0;
  bottom: 32px;
  right: 0;
  width: 8px;
  animation: spinner-right 1.5s linear infinite;
}

p.ERROR{
  color: #881811;
  font-weight: bold;
}

button.deleteButton {
  background-color: #BB3B33;
  color: white;
}
button.deleteButton:hover {
  background-color: #881811;
  color: white;
}
/*img{
  max-width: 100%;
  max-height: 100%;
}

img.active{
  border: 5px solid green;
}*/

.ContingentList {
    display: flex;
    flex-direction: column;
    background-color: rgba(42, 52, 57, 0.2);
    padding: 6px;
    border-radius: 4px;
}

.ContingentNameStats {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 14px;

}

.matchLists {
    display: flex;
    flex-direction: row;
}

.listHeadline {
    cursor: pointer;
    /* color: rgba(255, 53, 23, 1); */
    color: black;
    padding: 6px 0px;
    border-radius: 4px;
}

.listHeadline:hover {
    background-color: rgba(255, 53, 23, 0.2);
}

.videoListContainer {
    width: 50%;
    background-color: rgba(42, 52, 57, 0.2);
    border-radius: 4px;
    padding: 6px;
}

.videoList {}

.videoListEntry {
    margin-top: 12px;
}

.videoSubData {
    font-size: 10px;
}

.subList {}

.highlight {
    border-radius: 4px;
    padding: 0px 4px;
    background-color: rgba(45, 65, 66, 0.8);
    color: lightgray;
}

.highlight.cutContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
    display: table-cell;
}

a .highlight {
    color: rgba(0, 0, 255, 0.8);
}

.highlight.red {
    color: red;
}

.highlight.green {
    color: green;
}

.Month {
    background-color: rgba(42, 52, 57, 0.2);
    border-radius: 4px;
    padding: 6px;
    margin-top: 6px;
}

.summedMinutes {
    float: right;
    margin-right: 40px;
}
.userContainer {
    display: flex;
    flex-direction: row;
    padding: 12px 0 8px;
    border-bottom: 1px solid #444;
    /* background: #121212b8; */
    cursor: pointer;
}

.userContainer .userThumb {
    width: 64px;
    text-align: center;
}

.userContainer .userThumb .thump {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.userContainer .userInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

.userContainer .userInfo .arrow-size {
    align-self: center;
    font-size: 28px;
}

.userContainer .userInfo .userName {
    font-weight: bold;
}

.userContainer .userInfo .userRoles {
    font-size: 12px;
    color: #444444;
    margin-top: 6px;
}
.userContainer .userInfo .userId {
    font-size: 12px;
    color: #444444;
}

.userContainer .thumb.mbsc-ic {
    font-size: 3em;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    left: 5px;
}

.userContainer.newUser .userInfo .userName {
    position: relative;
    top: 19px;
}

.userThumb svg {
    font-size: 57px;
}
.userManagement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.userManagement .bottomInfoText {
  text-align: -webkit-center;
  color: #b3b3b3;
  margin-top: 30px;
  font-family: 'IBMPlexMono-Regular';
}

.userEdit {
  height: calc(100% - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}

h2.username {
  margin-bottom: 0px;
}

a.userid {
  font-size: 12px;
  margin-bottom: 10px;
}

.userEdit .mbsc-input.mbsc-control-w.mbsc-ltr,
.userEdit .mbsc-input.mbsc-control-w.mbsc-rtl {
  margin: 0 0 1.5em 0 !important;
}

.backButton {
  cursor: pointer;
  margin: 10px;
  font-size: 1.2em;
}


.loadingSpinner-userManagment {
  top: 10px;
}
.arrayOrInt {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.arrayOrIntOb {
  grid-gap: 8px;
  gap: 8px;
  display: flex;
  align-items: flex-end;
}
.contentMenu-background {
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  padding: 2px;
  color: rgb(177, 177, 177);
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.contentMenu-icon {
  font-size: 12px;
  padding-right: 4px;
}

.contentMenu-btn {
  padding: 0px 8px !important;
  white-space: nowrap !important;
}
.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}
.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}
@media only screen and (max-width: 500px) {
  .video-block {
    grid-template-columns: 160px 200px !important;
  }
  #content {
    min-width: 0px !important;
    margin: 0 !important;
  }
}
.camera-btn {
    color: #fff !important;
    font-weight: bold !important;
    width: 100%;
    height: 100%
}

.camera-btn-heigh {
    height: 100%;
}

.camera-btn-heigh-o button {
    height: 100%;
    background-color: #988b63 !important;
}

.camera-btn-heigh-o button:hover {
    height: 100%;
    background-color: #6b6450 !important;
}

.camera-btn-disable {
    background-color: rgb(179, 179, 179) !important;
    color: rgb(102, 102, 102) !important;
}

.status-head {
    padding: 10px;
    float: left;
    color: #888;
}

.status-body {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px 10px;
    font-size: 14px;
    word-wrap: break-word;
}

.status-body-info{
    float: left;
    padding-right: 18px;
    padding-bottom: 4px;
}

.status-pack-table {
    width: 100%;
    max-width: 800px;
    padding: 10px;
    overflow: hidden;
}

.status-pack-table th {
    text-align: left;
    color: #888;
    font-weight: 100;
    font-size: 17px;
    padding-right: 20px;
}

.status-pack-table td {
    font-size: 14px;
}

.after {
    clear: both;
    display: block;
}

.dot-true {
    color: #1be200;
    font-weight: bold;
}

.dot-false {
    color: #ff0000;
    font-weight: bold;
}

.comment {
    padding: 4px;
    margin-bottom: 6px;
    border-bottom: 1px solid #e0e0e0;
}

.comment:last-child {
    margin-bottom: 0px; 
    border-bottom: 0px;
}

.comment-time {
    font-size: 12px;
    color: #a5a5a5;
}

.comment-container {
    margin: 0 12px;
}

.comment-text {
    background-color: #f7f7f7;
    padding: 6px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
}

.comment-author button {
    float: right;
    top: -6px;
    min-width: 0px;
    max-width: 0px;
}

.comment-textinput {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.comment-textinput-btn {
    width: 100%;
    color: #fff !important;
    margin-top: 10px !important;
}

.bottom-cl01 {
    padding: 10px 10px 0 !important;
}

.custom-tooltip {
    background-color: rgb(252, 252, 252);
    border: 1px solid rgb(201, 201, 201);
    padding: 0 10px;
}

.classNameProp {
    margin: 0 0 20px !important;
}

.teletable-btn {
    color: rgba(0, 0, 0, 0.54);
    flex-shrink: 0;
    margin-left: 20px;
}

.telemetry-table {
    overflow: auto;
}

.telemetryPlot {
    padding: 24px;
}


.testsystem-pad {
    padding: 16px;
}
.testsystem-table {
    clear: both;
}

.testsystem-container {
    float: left;
    padding-right: 20px;
}

.runnote-overlay > div {
    width: 100% !important;
    height: 100%;
}

.runnote-overlay-tap {
    color: #fff !important;
}

.runnote-overlay-tap-none {
    color: transparent !important;
    pointer-events: none;
}

.runnote-grp {
    background-color: #e6e6e6;
    border: 1px solid #efefef;
    margin: 6px 6px;
    padding: 8px;
}

.runnote-grp-head {
    font-weight: bold;
    color: #151315;
}

.runnote-grp-body {
    color: #696969;
    font-size: 14px;
    padding-bottom: 10px;
}

.runnote-grp-body-cb-head {
    /*color: #989898;*/
    font-size: 14px;
    padding: 8px 0 4px;
}
.runnote-grp-body-dd-input::before {
    border-bottom: none !important;
}

.runnote-grp-body-cb-cb {
    padding: 0px !important;
    width: 20px !important;
    height: 28px !important;
    margin-right: 6px !important;
}

.runnote-grp-body-dd {
    width: 100%;
    margin-top: 10px;
}

.all {
    margin-bottom: -40px;
}

.runnote-grp-body-dd-input {
    width: 90%;
    z-index: 2;
}

.runnote-grp-body-dd-dd {
    z-index: 1;
    width: 100%;
    position: relative;
    top: -32px;
    float: right;
    border-bottom: none !important;
    color: transparent !important;
}

.runnote-grp-body-dd-dd-disablecolor {
    color: #123 !important;
}

.runnote-grp-dd {
    margin-bottom: -32px;
}

.runnote-checkboxGroup {
    flex-direction: row !important;
    margin-bottom: -10px;
}

.runnote-grp-line {
   
    border: 1px solid #efefef;
    margin: 4px 4px;
    padding: 10px 8px;
    height: 16px;
    cursor: pointer;
}

.runnote-grp-line-true {
    background-color: #2bff004a;
    border: 1px solid #d2d2d2;
    margin: 16px 4px;
    padding: 10px 8px;
    height: 16px;
    cursor: pointer;
}

.runnote-grp-line-grey {
    background-color: #e6e6e6;
}

.runnote-grp-line-yellow {
    background-color: #f4f80a40;
}

.runnote-grp-line-red {
    background-color: #ff000052;
}

.runnote-grp-line-yellow:hover {
    background-color: #f4f80a70;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-red:hover {
    background-color: #ff000070;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-grey:hover {
    background-color: #d8d8d8;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line:active {
    background-color: #00375352;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-index {
    padding-left: 20px;
    float: left;
}

.runnote-grp-line-time {
    float: right;
    padding-right: 20px;
}

.runnote-grp-btn button {
    display: table;
    margin: 0 auto;
    color: #fff !important;
    margin-top: 40px;
}

.runnote-grp-saveBtn {
    color: #fff !important;
    float: right;
    margin: 14px 7px 24px !important;
}

.runnote-grp-module-text {
    padding-top: 16px;
}

.anti-margin-top {
    margin-top: 0 !important;
}

.runnote-list {
    background-color: #5e5e5e;
    border-bottom: 1px solid;
    color: #fff;
    height: 60px;
}

.runnote-list-headtext {
    float: left;
    padding: 15px;
    font-size: 25px;
}

.runnote-list-headtextinfo {
    float: left;
    padding: 8px 15px;
    font-size: 25px;
}

.runnote-list-headinfo {
    position: absolute;
    top: 38px;
    left: 18px;
}

.runnote-list-btn {
    color: #fff !important;
    float: right;
    height: 38px !important;
    width: 38px !important;
    right: 20px !important;
    margin-top: 11px !important;
}

.runnote-list-dialog {
    padding: 6px 0;
    margin: 0px -18px;
}

.runnote-list-dialog-category {
    font-size: 18px;
    background-color: #f5f5f5;
    padding: 1px 6px;
    text-align: center;
}

.runnote-list-dialog-category-grp {
    padding: 1px 6px;
    background-color: #ff351782;
    margin: 4px 0;
    cursor: pointer;
}

.runnote-list-dialog-category-grp-false {
    padding: 1px 6px;
    background-color: #ff3c002d;
    margin: 4px 0;
}

.runnote-list-dialog-category-grp:hover {
    background-color: #00aaff;
}

.runnote-saveBtn {
    float: right;
    margin: 20px 6px !important;
    width: 65%;
}

.runnote-tooltip {
    background-color: #efefef;
    padding: 10px;
    margin: 10px 0 0;
    color: #565656;
    border: 1px solid #b9b9b9;
    font-size: 12px;
}

.runnote-grp-marker {
    position: absolute;
    left: 0;
    margin-left: 0px;
    height: 28px;
    width: 8px;
}

.runnote-marker-true {
    background-color: #2bff004a;
}

.runnote-marker-false {
    background-color: #ff00006b;
}

.runnote-marker-false-grp {
    background-color: #ff7b006b;
}


.runnote-grp-marker-grp {
    margin-top: 24px;
}

.runnote-grp-marker-drop {
    margin-top: 28px;
}

.runnote-banner-icon {
    background-color: #5e5e5e99;
    position: relative;
    width: 20px;
    height: 20px;
    /*top: 10px;*/
    left: -28px;
    border: 1px solid #dadada42;
    color: #fff;
    text-align: center;
    padding-top: 4px;
}

.runnote-grp-disable {
    pointer-events: none;
}

.runnote-grp-disable {
    pointer-events: none;
}

.runnote-grp-disable span {
    color: #858585;
}

.runnote-grp-check-icon {
    position: absolute;
    padding: 0px 10px;
    color: #66d66a;
    font-size: 18px;
}

.seriesBtnDelay {
    cursor: pointer;
    color: #fff !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
}

.seriesBtnDelay-o {
    cursor: pointer;
    color: #fff !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
    opacity: 0.2;
}


.runnote-grp-module-table-td {
    width: 50%;
    padding: 8px;
    border: 1px solid #e0e0e0;
}

.sys-update-err {
    background-color: #ff8a8a;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
}

.camera-update-console {
    background-color: #292929;
    border: 1px solid #bbbbbb;
    padding: 10px;
    border-radius: 4px;
    height: 400px;
    overflow: scroll;
}

.camera-update-console-row {
    margin: 6px 0;
    color: #fff;
    display: flex;
}

.camera-update-bottom-console {
    margin-top: 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.small-btn-console {
    height: 20px !important;
    padding: 0 6px !important;
}
.telemetry-container {
    padding: 10px;
}

.infobox-dot {
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    width: 14px;
    margin: 2px;
}

.dot-ok {
    background-color: rgba(88, 199, 55, 0.112);
    border: 2px solid rgb(88, 199, 55);
}

.dot-ok-fill {
    background-color: rgba(88, 199, 55, 0.712);
    border: 2px solid rgb(88, 199, 55);
}

.dot-middle {
    background-color: rgba(199, 177, 55, 0.112);
    border: 2px solid rgb(199, 177, 55);
}

.dot-middle-fill {
    background-color: rgba(199, 177, 55, 0.712);
    border: 2px solid rgb(199, 177, 55);
}

.dot-bad {
    background-color: rgba(199, 55, 55, 0.112);
    border: 2px solid rgb(199, 55, 55);
}

.dot-bad-fill {
    background-color: rgba(199, 55, 55, 0.712);
    border: 2px solid rgb(199, 55, 55);
}

.infobox-table td {
    vertical-align: middle;
    font-size: 0.875rem;
    font-weight: 400;
}

.tb-padding {
    height: 25px;
}

.infobox-table .table-state {
    padding-left: 12px;
}

.table-text {
    width: 116px;
}

.tele-graph-timedate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: #000000;
    color: #fff;
}

.forSeriesBtn {
    display: flex;
    /*align-items: end;*/
    flex-flow: wrap;
    justify-content: flex-end;
    padding-right: 10px;
    padding-bottom: 8px;
}

.seriesBtn {
    cursor: pointer;
    color: rgb(0, 0, 0) !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
}

.checkupBtn {
    width: 100%;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff !important;
    background-color: #FF3517;
    padding: 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.checkupBtn:hover {
    background-color: #0099ff;
}

.telemetryValues {
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    background-color: #eaeaea;
    padding-bottom: 6px;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
}

.telemetryValues-tb {
    padding: 8px 0 0 10px;
    white-space: nowrap;
    color: rgb(61, 61, 61);
}

.telemetryValues-sp {
    background-color: #ffffff;
    color: #000;
    border-radius: 25px;
    padding: 2px 12px;
}

.telemetryValues-overlay {
    position: fixed;
    left: 69px;
    right: 6px;
    top: 69px;
    box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.75);
}

.tablerow-match {
    background-color: rgb(215, 249, 205);
}

.tablerow-match:hover {
    background-color: rgb(215, 249, 205, 0.6) !important;
}

.teleInfoContainerBtn {
    width: 100%;
    outline: none;
    cursor: pointer;
    border: none;
    color: #fff !important;
    background-color: #FF3517;
    padding: 10px;
    border-radius: 4px;
    text-transform: uppercase;
}

.teleInfoContainerBtn:hover {
    background-color: #0099ff;
}

.btn-cont .react-datepicker-wrapper {
    width: 112px !important;
    margin-right: 38px !important;
}

.tele-more-btn {
    background-color: #03abfc;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 24px;
}

.tele-more-btn:hover {
    background-color: #008acf;
}
.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.user-edit {
    background-color: #cecece;
    margin: 10px 0 !important;
    padding: 10px !important;
}

.edit-role {
    display: flex;
    flex-wrap: wrap;
}

.edit-role-container {
    width: 33%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.trainerMail-btn {
    width: 50px;
    height: 50px;
    font-size: 28px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.trainerMail-btn:active {
    color: #af6767 !important;
}

.trainerMail-btn:hover {
    color: #0081c2 !important;
}

.sub-overview {
    height: 300px;
    overflow: scroll;
}
.InfoWindow-base {
    height: 60px;
}

.base-logo {
    padding-top: 6px;
    float: left;
}

.base-logo img {
    object-fit: cover;
}

.base-name {
    padding-top: 6px;
    padding-left: 10px;
    float: left;
    font-size: 14px;
    font-weight: bold;
}

.base-id {
    font-size: 12px;
    font-weight: normal;
}

.map-paper {
    padding: 5px;
}

.map {
    border: solid 1px #cecece;
}

.sponsor-boxed {
    height: 14px;
    width: 22px;
    text-align: center;
    padding-top: 0px;
    border: 1px solid #c5c5c5;
    background-color: #5bff02;
    margin: 2px;
}

.sponsor-boxed-true {
    height: 14px;
    width: 22px;
    text-align: center;
    padding-top: 0px;
    border: 1px solid #c5c5c5;
    margin: 2px;
    background-color: #ff0202;
}

.sponsor ul {
    display:table;
    margin:0px auto 0px auto;
}

.sponsor ul li {
    display: inline-block;
    list-style: none;
}

.sponsor-container {
    margin-left: -26px;
    margin-right: 0px;
}

.InfoWindow-head {
    font-size: 14px;
    color: rgb(105, 105, 105);
    font-weight: bold;
    padding: 8px 0 2px;
}

.inline {
    display: inline;
}

.xls {
    font-size: 12px;
    color: #2b74a5;
    font-weight: bold;
    padding-right: 10px;
}

.xls p {
    font-weight: normal;
    color: #2f2f2f;
    display: inline;
}

.xls-container {
    position: absolute;
    bottom: 50px;
    background-color: #fff;
    margin-right: 68px;
    margin-left: 4px;
    padding: 10px;
    border: solid 1px #cecece;
}

.map-underline {
    border-bottom: solid 1px #c5c5c5;
    padding-top: 8px;
    margin-bottom: 8px;
}

.label {
    box-sizing:border-box;
  background: #05F24C;
  box-shadow: 2px 2px 4px #333;
  border:5px solid #346FF7;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  animation: pulse 1s ease 1s 3;
}

.searchField {
    margin-bottom: 6px;
    margin-right: 14px;
    margin-top: 4px;
}

.map-legend {
    padding-top: 38px;
    margin: 4px;
    z-index: 0;
    position: absolute;
    right: 0px;
    top: 0px;
}

.legend-overlay {
    background-color: #fff;
    border: solid 1px rgb(194, 194, 194);
    padding: 0 10px;
}


.map-legend-off {
    display: none;
}

.legend-overlay img {
    vertical-align: middle;
}

.legend-overlay p {
    float: right;
    background-color: #f1f1f1;
    padding: 2px;
    border: solid 1px #e0e0e0;
}

.legend-overlay .underline {
    border-top: solid 1px #eaeaea;
}

.infoBox {
    float: right;
    background-color: #f1f1f1;
    padding: 2px;
    border: solid 1px #e0e0e0;
}

.high-one {
    margin-top: 15px;
}

.high-two {
    margin-top: 8px;
}

.high-three {
    margin-top: 2px;
}

.legend-btn {
    margin-top: -32px;
    position: fixed;
    right: 37px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    outline: none;
}

/*.map-route {
    background-color: #fff;
    border: solid 1px rgb(231, 231, 231);
    padding: 7px 6px;
    font-family: Roboto, Arial, sans-serif;
    user-select: none;
    font-size: 11px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}*/

.map-route-container-input {
    width: 100%;
}

.map-route-container-input-x {
    width: 88%;
}
.map-route-container-input-btn {
    width: 30px;
    height: 20px;
}

.map-route-container {
    padding-top: 80px;
    z-index: 0;
    position: absolute;
    left: 10px;
}

.map-route-container-x {
    background-color: #fff;
    border: solid 1px rgb(231, 231, 231);
    padding: 7px 6px;
    font-family: Roboto, Arial, sans-serif;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    width: 260px;
}

.map-route-container-btn {
    padding-top: 10px;
    z-index: 5;
    position: absolute;
    left: 106px;
}

.map-btn-top {
    margin-top: 8px !important;
}

.map-route-btn {
    background-color: #fff !important;
    min-height: 28px !important;
    height: 0px !important;
    border-radius: 2px !important;
    line-height: 0.5em !important;
    padding: 0 10px !important;
    font-size: 11px !important;
    color: rgb(0, 0, 0) !important;
    background-clip: padding-box;
    font-family: Roboto, Arial, sans-serif !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px !important;
    text-transform: none !important;
}

.routeBtn {
    height: 0px !important;
    min-height: 30px !important;
    line-height: none !important;
}

.routeTime {
    padding: 18px 0 6px;
    font-size: 14px;
    color: rgb(154, 154, 154);
}


.routeKm {
    font-size: 14px;
    color: rgb(154, 154, 154);
}
.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-head-video {
    display: grid;
    align-items: center;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.body-infoline {
    padding: 3px;
}

.layout-body a {
    color: #FF3517;
}

.layout-body-video {
    padding: 4px;
    padding-bottom: 0px;
}

.layout-body-video a {
    z-index: 0 !important;
    position: unset !important;
}

.layout-table {
    height: 300px;
    overflow-y: auto;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.test {
    width: 200px;
    height: 500px;
}

.game-btn {
    width: 50%;
}

.game-btn-full {
    width: 100%;
}

.game-btn span {
    font-weight: bold;
    color: #fff;
}

.game-btn-full span {
    font-weight: bold;
    color: #fff;
}

.game-addtest {
    padding: 20px;
}

.addtest-head {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.addtest-headpadding {
    padding: 20px 0 0px;
    color: rgba(0, 0, 0, 0.54);
}

.headpadding-ceck {
    padding: 0 0 0 20px;
}

.Select-control {
    border-color: #fff !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.Select-control:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.623) !important;
    
}

.Select-value {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Select-placeholder {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.addtest-textfield {
    width: 100%;
}

.addtest-btn {
    margin-top: 40px !important;
    margin-right: 20px !important;
}

.flowplayerContainer {
    max-height: 293px !important;
}

.layout-table::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.layout-table::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.layout-table::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.layout-table::-webkit-scrollbar-thumb:active {
  background: #707070;
}
.layout-table::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-corner {
  background: transparent;
}

.infoline-error {
    color: red;
}

.chips-testadd {
    display: flex;
    flex-wrap: wrap;
}

.formControl-testadd {
    width: 100%;
}

.no-refund {
    padding: 6px 10px;
    background: #82e28f;
    color: #696969 !important;
    border: none;
    text-align-last: center;
}

.btn-refund {
    padding: 6px 10px;
    background: #e28282;
    color: #fff !important;
    cursor: pointer;
    border: none;
    width: 100%;
}

.btn-refund:hover {
    background: #ce6868;
}


.package-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: row;
    border-top: 1px solid #a1a1a1;
    font-weight: bold;
    color: #FF3517;
}

.package-menu-icon {
    font-size: 14px;
    padding: 2px 26px;
    margin: 4px 0 -2px;
    cursor: pointer;
}

.package-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.packages-container {
    cursor: pointer;
    background: #e5e5e5;
    padding:  6px 10px;
    min-width: 200px;
    max-width: 400px;
    flex: 1 0;
    margin: 2px;
}

.pack-box {
    display: flex;
    padding: 6px 0;
    flex-direction: column;
    align-content: flex-start;
}

.pack-title {
    font-size: 12px;
    color: #707070;
}

.pack-text {
    font-size: 14px;
}


.buttwrapper-packages {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
}

.CSVImporter_ColumnDragSourceArea__box {
    width: 100%
}

.CSVImporter_ColumnDragSourceArea__page {
    overflow-y: auto;
}

.CSVImporter_FileSelector {
    padding: 4px;
    border: 2px dashed #000;
}


.infoText-finalCheck {
    padding: 12px 14px;
    background: #e3e3e3;
    color: #5c5c5c;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 40px;
    border: 1px solid #c3c3c3;
}

.clubX .textField-table-dropdown {
    max-width: 100%;
}
.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.body-infoline {
    padding: 3px;
}

.layout-body a {
    color: #FF3517;
}

.layout-body-video {
    padding: 4px;
    padding-bottom: 0px;
}

.layout-body-video a {
    z-index: 0 !important;
    position: unset !important;
}

.layout-table {
    height: 300px;
    overflow-y: auto;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.test {
    width: 200px;
    height: 500px;
}

.game-btn {
    width: 50%;
}

.game-btn-full {
    width: 100%;
}

.game-btn span {
    font-weight: bold;
    color: #fff;
}

.game-btn-full span {
    font-weight: bold;
    color: #fff;
}

.game-addtest {
    padding: 20px;
}

.addtest-head {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.addtest-headpadding {
    padding: 20px 0 0px;
    color: rgba(0, 0, 0, 0.54);
}

.headpadding-ceck {
    padding: 0 0 0 20px;
}

.Select-control {
    border-color: #fff !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.Select-control:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.623) !important;
    
}

.Select-value {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Select-placeholder {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.addtest-textfield {
    width: 100%;
}

.addtest-btn {
    margin-top: 40px !important;
    margin-right: 20px !important;
}

.flowplayerContainer {
    max-height: 293px !important;
}

.layout-table::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.layout-table::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.layout-table::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.layout-table::-webkit-scrollbar-thumb:active {
  background: #707070;
}
.layout-table::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-corner {
  background: transparent;
}

.infoline-error {
    color: red;
}

.chips-testadd {
    display: flex;
    flex-wrap: wrap;
}

.formControl-testadd {
    width: 100%;
}

.fromTo {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    align-items: center;
}

.fromTo input {
    width: 70px;
}

.btnClip {
    color: #fff !important;
}

.prevbox {
    background-color: #2d2d39;
}

.prevbox-header {
    
}

.prevbox-content {
    padding: 4px
}

.prevbox-content-time {
    font-weight: 700;
    color: #ccc;
}

.prevbox-content-name {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    line-height: normal;
    margin-bottom: .5rem;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.prevbox-content-desc {
    color: #ccc;
    padding-bottom: 30px;
}

.prevbox-switchBtn {
    position: absolute;
    margin-top: 7px;
    margin-left: 9px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.socialMedia {
  padding: 20px;
}
.socialMedia .socialMedia-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}
.socialMedia .socialMedia-heading .heading-searchBar {
  display: flex;
}
.socialMedia .socialMedia-heading .heading-searchBar .searchBar-btn {
  margin-top: 12px !important;
  width: 240px !important;
  margin-left: 20px !important;
  color: #fff !important;
  font-weight: bold !important;
}
.socialMedia .socialMedia-heading .heading-searchBar label {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.socialMedia .socialMedia-heading .heading-filter {
  display: flex;
  padding: 10px 0;
}
.socialMedia .socialMedia-heading .heading-filter .filter-btn {
  color: #fff !important;
  margin: 4px 4px !important;
  padding: 0 10px !important;
  height: 24px !important;
}
.socialMedia .socialMedia-heading .heading-filter .filter-btn-disabled {
  background-color: rgb(177, 177, 177) !important;
  color: #4c4c4c !important;
  margin: 4px 4px !important;
  padding: 0 10px !important;
  height: 24px !important;
}
.socialMedia .socialMedia-heading .heading-filter .filter-text {
  align-items: center;
  flex-direction: row;
  display: flex;
  min-width: 56px;
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6784313725);
}
.socialMedia .socialMedia-heading .heading-filter .flex-column {
  flex-direction: column;
  display: flex;
}
.socialMedia .socialMedia-heading .heading-secondFilter {
  margin-left: 54px;
}
.socialMedia .socialMedia-content .content-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 -8px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box {
  padding: 20px 10px;
  margin: 8px;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.0784313725);
  border-radius: 4px;
  cursor: pointer;
  background-color: #f3f3f3;
  /*.box-msgNumber {
    float: right;
    margin-top: -30px;
    background-color: #69cdff;
    color: #fff;
    border-radius: 100px;
    padding: 4px 10px;
    border: 1px solid #e0e0e0;
  }*/
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-matchId {
  position: absolute;
  margin: -18px 0px 0 -8px;
  font-size: 10px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info {
  position: absolute;
  margin-left: -10px;
  margin-top: 22px;
  width: 28px;
  opacity: 0.6;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info .info-status {
  background-color: #74cc75;
  color: #404040;
  padding: 5px 10px 5px 5px;
  border-right: 1px solid #949494;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #949494;
  border-radius: 0 6px 6px 0;
  height: 20px;
  margin-bottom: 2px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info .info-status svg {
  padding: 2px 0px 0px 5px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info .info-paywall {
  background-color: rgba(123, 123, 123, 0.4392156863);
  color: #404040;
  padding: 5px 10px 5px 5px;
  border-right: 1px solid #949494;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #949494;
  border-radius: 0 6px 6px 0;
  margin-bottom: 2px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info .info-paywall p {
  margin: 2px;
  padding-left: 1px;
  font-weight: bold;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-info .info-paywall svg {
  padding: 2px 0px 0px 2px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-head {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  margin-bottom: 10px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-head .head-club {
  width: 180px;
  text-align: center;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-head .head-club .club-id {
  font-weight: 100;
  font-size: 14px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-content {
  display: flex;
  justify-content: space-around;
  padding: 14px 0 30px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-footer {
  display: flex;
  justify-content: space-around;
  background-color: #ececec;
  border-radius: 6px;
  padding: 6px;
  border: 1px solid #dedede;
  margin: 0 80px;
  color: #2b2b2b;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-msgCounter {
  float: right;
  margin-top: -24px;
  padding-right: 8px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-msgCounter .msgCounter-inner {
  background-color: #69cdff;
  color: #fff;
  border-radius: 100px;
  padding: 4px 10px;
  border: 1px solid #e0e0e0;
  margin-top: -6px;
}
.socialMedia .socialMedia-content .content-boxes .inner-box .box-msgCounter .msgCounter-puls {
  color: #fff;
  border-radius: 100px;
  padding: 4px 10px;
  border: 1px solid #e0e0e0;
  margin-top: -6px;
  background-color: #69cdff;
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes color {
  0% {
    background-color: rgb(255, 79, 79);
  }
  50% {
    background-color: rgb(255, 119, 119);
  }
  100% {
    background-color: rgb(255, 79, 79);
  }
}
.socialMedia .socialMedia-content .content-boxes .inner-box:hover {
  background-color: #e0e0e0;
  border-color: rgba(0, 0, 0, 0.2392156863);
}
.socialMedia .socialMedia-content .content-boxes .inner-box:hover .box-info {
  opacity: 1;
}
.socialMedia .socialMedia-content .content-boxes .inner-box:hover .box-footer {
  border: 1px solid #a1a1a1;
}
.socialMedia .socialMedia-content .content-boxes .inner-box:hover .box-msgCounter .msgCounter-inner {
  background-color: #FF3517;
}
.socialMedia .socialMedia-content .content-moreBtn {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}
.socialMedia .socialMedia-content .content-moreBtn .btn {
  color: #FF3517 !important;
  font-size: 16px;
  cursor: pointer;
}
.socialMedia .socialMedia-content .content-moreBtn .btn:hover {
  color: #0086c9 !important;
}
.socialMedia .socialMedia-content .content-newChat {
  padding-bottom: 20px;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar {
  display: flex;
  justify-content: space-between;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .left {
  display: flex;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .left .topBar-title {
  border-bottom-right-radius: 6px;
  font-size: 12px;
  padding-top: 6px;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .right {
  display: flex;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-title {
  background-color: #ececec;
  color: #585858;
  padding: 4px 12px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-msgCounter {
  background-color: #ececec;
  color: #585858;
  padding: 4px 12px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-msgCounterPuls {
  color: #fff;
  border-left: none;
  border-right: none;
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes msgCounterColor {
  0% {
    background-color: rgb(255, 79, 79);
  }
  50% {
    background-color: rgb(255, 119, 119);
  }
  100% {
    background-color: rgb(255, 79, 79);
  }
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-btn {
  background-color: #FF3517;
  color: #fff;
  padding: 4px 12px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-btn:hover {
  background-color: #0089ce;
}
.socialMedia .socialMedia-content .content-newChat .newChat-topBar .topBar-btnDisabled {
  background-color: #ececec;
  color: #585858;
  padding: 4px 12px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.socialMedia .socialMedia-content .content-newChat .newChat-title {
  position: absolute;
  margin-top: -10px;
  margin-left: 10px;
  background-color: #fff;
  padding: 0px 6px;
}
.socialMedia .pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin: 0 0 20px;
  border-radius: 4px;
}
.socialMedia .pagination li {
  display: inline;
}
.socialMedia .pagination div {
  cursor: pointer;
  position: relative;
  float: left;
  padding: 6px 14px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #FF3517;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.socialMedia .pagination div:hover {
  z-index: 3;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.socialMedia .pagination .disabled div {
  color: #9e9e9e;
  cursor: default;
}
.socialMedia .pagination .active div {
  background-color: #FF3517;
  color: #fff;
  cursor: default;
}
.socialMedia .pagination-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -13px 0 10px;
  font-size: 12px;
}

.socialMedia-sorting {
  margin: 0 0 10px 62px;
  display: flex;
  flex-wrap: wrap;
}
.socialMedia-sorting .sorting-row {
  display: flex;
  padding-right: 24px;
}
.socialMedia-sorting .sorting-row .row-text {
  font-size: 14px;
}
.socialMedia-sorting .sorting-row .row-btn {
  cursor: pointer;
  padding-left: 8px;
  color: #717171;
}
.socialMedia-sorting .sorting-row .row-disable {
  color: #c1c1c1;
}
.socialMedia-sorting .sorting-row .row-active {
  color: #FF3517;
}
.socialMedia-sorting .sorting-row:last-child {
  padding-right: 0;
}

.socialMedia-contentContainers .contentContainers-header {
  display: flex;
  align-items: center;
  margin: -18px -18px 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid #b7b7b7;
}
.socialMedia-contentContainers .contentContainers-header .header-text {
  display: flex;
  align-items: center;
  margin-left: 40px;
  color: #949494;
  text-overflow: ellipsis;
  overflow: hidden;
}
.socialMedia-contentContainers .contentContainers-header .header-text p {
  margin: 2px 0 0;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bolder;
  white-space: nowrap;
}
.socialMedia-contentContainers .contentContainers-content {
  list-style-type: none;
  white-space: nowrap;
}
.socialMedia-contentContainers .contentContainers-content .content-infobox {
  background: #fbfbfb;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #b7b7b7;
}
.socialMedia-contentContainers .contentContainers-content .content-infobox .infobox-table th {
  float: left;
  padding: 4px 0;
}
.socialMedia-contentContainers .contentContainers-content .content-infobox .infobox-table td {
  font-size: 16px;
  padding-left: 14px;
  white-space: normal;
}
.socialMedia-contentContainers .contentContainers-content .content-player {
  background: #fbfbfb;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #b7b7b7;
}
.socialMedia-contentContainers .contentContainers-content .content-chat {
  background: #fbfbfb;
  border-radius: 6px;
  border: 1px solid #b7b7b7;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-headbar {
  border-bottom: 1px solid #b7b7b7;
  padding: 2px 12px 2px;
  font-size: 18px;
  font-weight: bold;
  color: #8a8a8a;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content {
  min-height: 220px;
  max-height: 400px;
  overflow: overlay;
  padding: 0 14px 10px 10px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content .content-massage {
  padding: 5px 0;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content .content-massage .massage-user {
  padding: 0px 6px 2px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content .content-massage .massage-user .user-date {
  font-size: 10px;
  margin-top: 5px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content .content-massage .massage-msg {
  cursor: pointer;
  padding: 6px 12px;
  background: #f2f9fd;
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  font-size: 14px;
  white-space: normal;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content .content-nochatmsg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #afafaf;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-thumb:active {
  background: #707070;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.socialMedia-contentContainers .contentContainers-content .content-chat .chat-content::-webkit-scrollbar-corner {
  background: transparent;
}
.socialMedia-contentContainers .contentContainers-content .content-camera .camera-headtext {
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
}
.socialMedia-contentContainers .contentContainers-content .content-camera th {
  float: left;
  padding: 4px 0;
}
.socialMedia-contentContainers .contentContainers-content .content-camera td {
  font-size: 16px;
}
.socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket {
  display: flex;
  padding-left: 2px;
  padding-bottom: 14px;
}
.socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket .ticket-btn {
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: rgb(40, 136, 183);
  padding-left: 6px;
}
.socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket .ticket-btn a, .socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket .ticket-btn a:focus, .socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket .ticket-btn a:visited, .socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket .ticket-btn a:active {
  color: inherit;
  text-decoration: none;
}
.socialMedia-contentContainers .contentContainers-content .content-camera .camera-ticket:last-child {
  padding-bottom: 0;
}
.socialMedia-contentContainers .contentContainers-content .content-upload .upload-headtext {
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.socialMedia-contentContainers .contentContainers-content .content-upload .upload-helptext {
  padding-bottom: 20px;
}
.socialMedia-contentContainers .contentContainers-content .content-upload .upload-helptext p {
  padding: 0;
  margin: 2px 0 0;
  font-size: 12px;
}
.socialMedia-contentContainers .contentContainers-content .content-upload .upload-content {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialMedia-contentContainers .contentContainers-content .content-upload .upload-graph {
  height: 300px;
}
.rjsf .field-string {
  width: 100%;
  display: flex;
  padding: 4px 0;
}
.rjsf .control-label {
  width: 120px !important;
}
.rjsf .form-control {
  width: 100%;
}
.rjsf .field-object {
  padding: 4px 0;
}
.rjsf .btn {
  width: 100%;
  height: 40px;
  background: #ff3517;
  font-size: 22px;
  border: 0;
}
.rjsf .btn:hover {
  background: rgba(255, 54, 23, 0.7411764706);
}
.mainContent {
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding-top: 80px;
    padding-right: 16px;
}

.menu {
    width: 100%;
    height: 100%;
    background-color: #000000;
    margin-top: 62px;
}

.gameTest-ball {
  color: #000000;
  border-radius: 100px;
  background-color: white;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 11px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  margin-top: -50px;
  margin-left: 34px;
}

.fa-layers-counter {
  background-color: #ff253b91;
  border-radius: 1em;
  height: 1.5em;
  line-height: 1.5;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: -0.75em;
  right: 12px;
  text-overflow: ellipsis;
  top: 2px;
  transform: scale(.85);
  transform-origin: top right;
  display: inline-block;
  position: absolute;
  text-align: center;
}
 
.sidenav---sidenav---_2tBP {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1006;
  min-width: 64px;
  background-color: black;
  transition: min-width 0.15s;
  overflow: auto;
}


.sidenav---sidenav---_2tBP::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sidenav---sidenav---_2tBP::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidenav---sidenav---_2tBP::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidenav---sidenav---_2tBP::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 240px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  display: inline-block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
  cursor: pointer;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  position: static;
  border: none;
  float: left;
  clear: both;
  width: 100%;
  background: transparent;
  overflow: hidden;
  box-shadow: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 {
  position: relative;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover::before {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #FF3517;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before {
  content: " ";
  width: 2px;
  height: 20px;
  left: 10px;
  top: 4px;
  position: absolute;
  border-left: 2px #FF3517 solid;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
  background: transparent;
  color: #FF3517;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  color: #fff;
  padding: 0 14px 0 20px;
  line-height: 28px;
}
.sidenav---sidenav-toggle---1KRjR {
  position: relative;
  float: left;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.sidenav---sidenav-toggle---1KRjR:focus {
  outline: none;
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  display: block;
  width: 20px;
  height: 2px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1px;
  transition: all 0.15s;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 + .sidenav---icon-bar---u1f02 {
  margin-top: 4px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  width: 25px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(1) {
  transform: rotate(45deg);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(3) {
  margin-top: -8px;
  transform: rotate(-45deg);
}
.sidenav---sidenav-nav---3tvij {
  float: left;
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
  width: 100%;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  clear: both;
  position: relative;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
  cursor: default;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: -1;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  display: block;
  float: left;
  width: 64px;
  height: 50px;
  margin-right: -6px;
  /*vertical-align: top;*/
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  line-height: 50px;
  text-align: center;
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > * {
  color: #fff;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  display: none;
}
.sidenav---sidenav-subnav---1EN61 {
  min-width: 200px;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 0;
  left: 64px;
  background: #fff;
  border: 1px #fff solid;
  
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  margin: 0 24px;
  line-height: 48px;
  border-bottom: 1px #fff solid;
  font-size: 14px;
  font-weight: normal;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 8px;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child {
  margin-bottom: 8px;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #FF3517;
  font-weight: bold;
  background: none;
  cursor: default;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 13px;
  line-height: 30px;
  padding: 0 24px;
  cursor: pointer;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}
.sidenav---sidenav---_2tBP {
  box-sizing: border-box;
  line-height: 20px;
}
.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
  box-sizing: inherit;
}

.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}
.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}
.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}
.Tab.active button{
  background-color: rgba(255, 52,23, 0.2);
}

.formRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  grid-gap: 22px;
  gap: 22px;
}

.formRow-input {
  display: flex;
}

.formRow-input-id {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar {
    position: fixed;
    min-width: 100%;
    background-color: #000000;
    padding: 0px;
    z-index: 101;

    padding-left: 74px;
    color: #fff;
    display: block;
    font-size: 24px;
    font-family: Roboto,sans-serif;
    white-space: nowrap;
    float: left;
    outline: none!important;
    line-height: 20px;
}

a {
    color: #fff;
    text-decoration: none
}

.navbar-logo :hover {
    color: #FF3517;
    transition: color .2s ease;
}

.navbar-logo span {
    color: #FF3517;
}

.navbar-logo {
    float: left;
}

.navbar-loginName {
    float: right;
    font-size: 16px;
    margin-right: 100px;
    white-space: normal;
}

.logout-btn {
    float: right;
    cursor: pointer;
    padding-left: 18px;
}

.environment-text {
    display: flex;
    position: absolute;
    font-size: 10px;
    background: #000000;
    padding: 4px;
    height: 15px;
    margin-top: 28px;
    border-radius: 0px 0 4px 4px;
    right: 122px;
}

.environment-text-p {
    cursor: pointer;
    margin: 0;
    padding: 0 2px;
}

.environment-version {
    display: flex;
    position: absolute;
    font-size: 10px;
    background: #000000;
    padding: 4px;
    height: 15px;
    margin-top: 28px;
    border-radius: 0px 0 4px 4px;
    right: 210px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff;
  height: 100%;
}

html {
  height: 100%;
}

root {
  height: 100%;
}

.base {
 height: 100%;
}

.mainContent {
  padding-bottom: 40px;
}

.MuiButton-root {
  background: linear-gradient(45deg, #FF3517 30%, #FF3517 90%);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  
}

.btn {
  color: #fff !important;
}
.licenseClaimContainer a{
  color: blue
}
