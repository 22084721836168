@media only screen and (max-width: 500px) {
  .video-block {
    grid-template-columns: 160px 200px !important;
  }

  #content {
    min-width: 0px !important;
    margin: 0 !important;
  }
}
