.contentMenu-background {
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  padding: 2px;
  color: rgb(177, 177, 177);
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.contentMenu-icon {
  font-size: 12px;
  padding-right: 4px;
}

.contentMenu-btn {
  padding: 0px 8px !important;
  white-space: nowrap !important;
}