
.colorBar {
  display: flex;

  .colorBox {
    height: 28px;
    width: 28px;
    margin: 4px;
    border: 1px solid #949494;
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
  }
}

.cover {
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    cursor: pointer;
    background: #636363;
    color: #fff;
    border-radius: 4px 4px 0 0;
    box-shadow: #00000026 0px 0px 0px 1px, #00000026 0px 8px 16px;
    width: 200px;
}

.swatch {
  display: inline-block;
  cursor: pointer;

  .colorBox {
    height: 28px;
    width: 28px;
    margin: 4px;
    border: 1px solid #949494;
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
  }
}