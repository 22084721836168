p.ERROR{
  color: #881811;
  font-weight: bold;
}

button.deleteButton {
  background-color: #BB3B33;
  color: white;
}
button.deleteButton:hover {
  background-color: #881811;
  color: white;
}