.cityOverlay {
  width: 100%;

  div {
    min-width: 100%;
  }
}

.cityOverlay-content {
  //height: 300px;

  div {
    min-width: 100%;
  }

  .content-createCity {
    margin-top: 16px;
    background: #d5d5d5;
    border-radius: 5px;
    overflow: hidden;
    height: 320px;
    overflow-y: scroll;

    .createCity-input {
      margin: 16px 4px;
    }
  }

  .content-searchBox {
    margin-top: 16px;
    background: #d5d5d5;
    border-radius: 5px;
    overflow: hidden;
    // height: 300px;
    height: 300px;
    overflow-y: scroll;
    
    .searchBox-title {
      padding: 10px;
    }

    .searchBox-city {
      margin: 10px;
      background: whitesmoke;
      padding: 13px;
      cursor: pointer;
    }

    .searchBox-city:hover {
      background: rgb(231, 231, 231);
    }

    .searchBox-createCity {
      background: #5c5c5c;
      color: #ebebeb;
    }

    .searchBox-createCity:hover {
      background: #3d3d3d;
      color: #ebebeb;
    }

    .searchBox-notFoundCity {
      text-align-last: center;
      padding: 40px 0 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}