.camera-btn {
    color: #fff !important;
    font-weight: bold !important;
    width: 100%;
    height: 100%
}

.camera-btn-heigh {
    height: 100%;
}

.camera-btn-heigh-o button {
    height: 100%;
    background-color: #988b63 !important;
}

.camera-btn-heigh-o button:hover {
    height: 100%;
    background-color: #6b6450 !important;
}

.camera-btn-disable {
    background-color: rgb(179, 179, 179) !important;
    color: rgb(102, 102, 102) !important;
}

.status-head {
    padding: 10px;
    float: left;
    color: #888;
}

.status-body {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px 10px;
    font-size: 14px;
    word-wrap: break-word;
}

.status-body-info{
    float: left;
    padding-right: 18px;
    padding-bottom: 4px;
}

.status-pack-table {
    width: 100%;
    max-width: 800px;
    padding: 10px;
    overflow: hidden;
}

.status-pack-table th {
    text-align: left;
    color: #888;
    font-weight: 100;
    font-size: 17px;
    padding-right: 20px;
}

.status-pack-table td {
    font-size: 14px;
}

.after {
    clear: both;
    display: block;
}

.dot-true {
    color: #1be200;
    font-weight: bold;
}

.dot-false {
    color: #ff0000;
    font-weight: bold;
}

.comment {
    padding: 4px;
    margin-bottom: 6px;
    border-bottom: 1px solid #e0e0e0;
}

.comment:last-child {
    margin-bottom: 0px; 
    border-bottom: 0px;
}

.comment-time {
    font-size: 12px;
    color: #a5a5a5;
}

.comment-container {
    margin: 0 12px;
}

.comment-text {
    background-color: #f7f7f7;
    padding: 6px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
}

.comment-author button {
    float: right;
    top: -6px;
    min-width: 0px;
    max-width: 0px;
}

.comment-textinput {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.comment-textinput-btn {
    width: 100%;
    color: #fff !important;
    margin-top: 10px !important;
}

.bottom-cl01 {
    padding: 10px 10px 0 !important;
}

.custom-tooltip {
    background-color: rgb(252, 252, 252);
    border: 1px solid rgb(201, 201, 201);
    padding: 0 10px;
}

.classNameProp {
    margin: 0 0 20px !important;
}

.teletable-btn {
    color: rgba(0, 0, 0, 0.54);
    flex-shrink: 0;
    margin-left: 20px;
}

.telemetry-table {
    overflow: auto;
}

.telemetryPlot {
    padding: 24px;
}


.testsystem-pad {
    padding: 16px;
}
.testsystem-table {
    clear: both;
}

.testsystem-container {
    float: left;
    padding-right: 20px;
}

.runnote-overlay > div {
    width: 100% !important;
    height: 100%;
}

.runnote-overlay-tap {
    color: #fff !important;
}

.runnote-overlay-tap-none {
    color: transparent !important;
    pointer-events: none;
}

.runnote-grp {
    background-color: #e6e6e6;
    border: 1px solid #efefef;
    margin: 6px 6px;
    padding: 8px;
}

.runnote-grp-head {
    font-weight: bold;
    color: #151315;
}

.runnote-grp-body {
    color: #696969;
    font-size: 14px;
    padding-bottom: 10px;
}

.runnote-grp-body-cb-head {
    /*color: #989898;*/
    font-size: 14px;
    padding: 8px 0 4px;
}
.runnote-grp-body-dd-input::before {
    border-bottom: none !important;
}

.runnote-grp-body-cb-cb {
    padding: 0px !important;
    width: 20px !important;
    height: 28px !important;
    margin-right: 6px !important;
}

.runnote-grp-body-dd {
    width: 100%;
    margin-top: 10px;
}

.all {
    margin-bottom: -40px;
}

.runnote-grp-body-dd-input {
    width: 90%;
    z-index: 2;
}

.runnote-grp-body-dd-dd {
    z-index: 1;
    width: 100%;
    position: relative;
    top: -32px;
    float: right;
    border-bottom: none !important;
    color: transparent !important;
}

.runnote-grp-body-dd-dd-disablecolor {
    color: #123 !important;
}

.runnote-grp-dd {
    margin-bottom: -32px;
}

.runnote-checkboxGroup {
    flex-direction: row !important;
    margin-bottom: -10px;
}

.runnote-grp-line {
   
    border: 1px solid #efefef;
    margin: 4px 4px;
    padding: 10px 8px;
    height: 16px;
    cursor: pointer;
}

.runnote-grp-line-true {
    background-color: #2bff004a;
    border: 1px solid #d2d2d2;
    margin: 16px 4px;
    padding: 10px 8px;
    height: 16px;
    cursor: pointer;
}

.runnote-grp-line-grey {
    background-color: #e6e6e6;
}

.runnote-grp-line-yellow {
    background-color: #f4f80a40;
}

.runnote-grp-line-red {
    background-color: #ff000052;
}

.runnote-grp-line-yellow:hover {
    background-color: #f4f80a70;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-red:hover {
    background-color: #ff000070;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-grey:hover {
    background-color: #d8d8d8;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line:active {
    background-color: #00375352;
    border-bottom: 1px solid #FF3517;
}

.runnote-grp-line-index {
    padding-left: 20px;
    float: left;
}

.runnote-grp-line-time {
    float: right;
    padding-right: 20px;
}

.runnote-grp-btn button {
    display: table;
    margin: 0 auto;
    color: #fff !important;
    margin-top: 40px;
}

.runnote-grp-saveBtn {
    color: #fff !important;
    float: right;
    margin: 14px 7px 24px !important;
}

.runnote-grp-module-text {
    padding-top: 16px;
}

.anti-margin-top {
    margin-top: 0 !important;
}

.runnote-list {
    background-color: #5e5e5e;
    border-bottom: 1px solid;
    color: #fff;
    height: 60px;
}

.runnote-list-headtext {
    float: left;
    padding: 15px;
    font-size: 25px;
}

.runnote-list-headtextinfo {
    float: left;
    padding: 8px 15px;
    font-size: 25px;
}

.runnote-list-headinfo {
    position: absolute;
    top: 38px;
    left: 18px;
}

.runnote-list-btn {
    color: #fff !important;
    float: right;
    height: 38px !important;
    width: 38px !important;
    right: 20px !important;
    margin-top: 11px !important;
}

.runnote-list-dialog {
    padding: 6px 0;
    margin: 0px -18px;
}

.runnote-list-dialog-category {
    font-size: 18px;
    background-color: #f5f5f5;
    padding: 1px 6px;
    text-align: center;
}

.runnote-list-dialog-category-grp {
    padding: 1px 6px;
    background-color: #ff351782;
    margin: 4px 0;
    cursor: pointer;
}

.runnote-list-dialog-category-grp-false {
    padding: 1px 6px;
    background-color: #ff3c002d;
    margin: 4px 0;
}

.runnote-list-dialog-category-grp:hover {
    background-color: #00aaff;
}

.runnote-saveBtn {
    float: right;
    margin: 20px 6px !important;
    width: 65%;
}

.runnote-tooltip {
    background-color: #efefef;
    padding: 10px;
    margin: 10px 0 0;
    color: #565656;
    border: 1px solid #b9b9b9;
    font-size: 12px;
}

.runnote-grp-marker {
    position: absolute;
    left: 0;
    margin-left: 0px;
    height: 28px;
    width: 8px;
}

.runnote-marker-true {
    background-color: #2bff004a;
}

.runnote-marker-false {
    background-color: #ff00006b;
}

.runnote-marker-false-grp {
    background-color: #ff7b006b;
}


.runnote-grp-marker-grp {
    margin-top: 24px;
}

.runnote-grp-marker-drop {
    margin-top: 28px;
}

.runnote-banner-icon {
    background-color: #5e5e5e99;
    position: relative;
    width: 20px;
    height: 20px;
    /*top: 10px;*/
    left: -28px;
    border: 1px solid #dadada42;
    color: #fff;
    text-align: center;
    padding-top: 4px;
}

.runnote-grp-disable {
    pointer-events: none;
}

.runnote-grp-disable {
    pointer-events: none;
}

.runnote-grp-disable span {
    color: #858585;
}

.runnote-grp-check-icon {
    position: absolute;
    padding: 0px 10px;
    color: #66d66a;
    font-size: 18px;
}

.seriesBtnDelay {
    cursor: pointer;
    color: #fff !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
}

.seriesBtnDelay-o {
    cursor: pointer;
    color: #fff !important;
    border: 0;
    margin: 2px;
    padding: 6px;
    outline: none;
    opacity: 0.2;
}


.runnote-grp-module-table-td {
    width: 50%;
    padding: 8px;
    border: 1px solid #e0e0e0;
}

.sys-update-err {
    background-color: #ff8a8a;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
}

.camera-update-console {
    background-color: #292929;
    border: 1px solid #bbbbbb;
    padding: 10px;
    border-radius: 4px;
    height: 400px;
    overflow: scroll;
}

.camera-update-console-row {
    margin: 6px 0;
    color: #fff;
    display: flex;
}

.camera-update-bottom-console {
    margin-top: 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.small-btn-console {
    height: 20px !important;
    padding: 0 6px !important;
}