.ContingentList {
    display: flex;
    flex-direction: column;
    background-color: rgba(42, 52, 57, 0.2);
    padding: 6px;
    border-radius: 4px;
}

.ContingentNameStats {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 14px;

}

.matchLists {
    display: flex;
    flex-direction: row;
}

.listHeadline {
    cursor: pointer;
    /* color: rgba(255, 53, 23, 1); */
    color: black;
    padding: 6px 0px;
    border-radius: 4px;
}

.listHeadline:hover {
    background-color: rgba(255, 53, 23, 0.2);
}

.videoListContainer {
    width: 50%;
    background-color: rgba(42, 52, 57, 0.2);
    border-radius: 4px;
    padding: 6px;
}

.videoList {}

.videoListEntry {
    margin-top: 12px;
}

.videoSubData {
    font-size: 10px;
}

.subList {}

.highlight {
    border-radius: 4px;
    padding: 0px 4px;
    background-color: rgba(45, 65, 66, 0.8);
    color: lightgray;
}

.highlight.cutContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
    display: table-cell;
}

a .highlight {
    color: rgba(0, 0, 255, 0.8);
}

.highlight.red {
    color: red;
}

.highlight.green {
    color: green;
}

.Month {
    background-color: rgba(42, 52, 57, 0.2);
    border-radius: 4px;
    padding: 6px;
    margin-top: 6px;
}

.summedMinutes {
    float: right;
    margin-right: 40px;
}