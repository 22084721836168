.userManagement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.userManagement .bottomInfoText {
  text-align: -webkit-center;
  color: #b3b3b3;
  margin-top: 30px;
  font-family: 'IBMPlexMono-Regular';
}

.userEdit {
  height: calc(100% - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;


}

h2.username {
  margin-bottom: 0px;
}

a.userid {
  font-size: 12px;
  margin-bottom: 10px;
}

.userEdit .mbsc-input.mbsc-control-w.mbsc-ltr,
.userEdit .mbsc-input.mbsc-control-w.mbsc-rtl {
  margin: 0 0 1.5em 0 !important;
}

.backButton {
  cursor: pointer;
  margin: 10px;
  font-size: 1.2em;
}


.loadingSpinner-userManagment {
  top: 10px;
}