.navbar {
    position: fixed;
    min-width: 100%;
    background-color: #000000;
    padding: 0px;
    z-index: 101;

    padding-left: 74px;
    color: #fff;
    display: block;
    font-size: 24px;
    font-family: Roboto,sans-serif;
    white-space: nowrap;
    float: left;
    outline: none!important;
    line-height: 20px;
}

a {
    color: #fff;
    text-decoration: none
}

.navbar-logo :hover {
    color: #FF3517;
    transition: color .2s ease;
}

.navbar-logo span {
    color: #FF3517;
}

.navbar-logo {
    float: left;
}

.navbar-loginName {
    float: right;
    font-size: 16px;
    margin-right: 100px;
    white-space: normal;
}

.logout-btn {
    float: right;
    cursor: pointer;
    padding-left: 18px;
}

.environment-text {
    display: flex;
    position: absolute;
    font-size: 10px;
    background: #000000;
    padding: 4px;
    height: 15px;
    margin-top: 28px;
    border-radius: 0px 0 4px 4px;
    right: 122px;
}

.environment-text-p {
    cursor: pointer;
    margin: 0;
    padding: 0 2px;
}

.environment-version {
    display: flex;
    position: absolute;
    font-size: 10px;
    background: #000000;
    padding: 4px;
    height: 15px;
    margin-top: 28px;
    border-radius: 0px 0 4px 4px;
    right: 210px;
}
