.valid-input {
  display: flex;
  flex-direction: row;
}

.valid-content {
  display: flex;
  flex-wrap: wrap;

  .content-itm {
    background: #cecece;
    margin: 4px;
    padding: 2px 6px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .itm-content {
      .content-head {
        font-size: 10px;
      }

      .content-text {
        font-size: 14px;
      }
    }

    .itm-delete {
      padding: 2px 7px;
      margin: 0 0px 0 12px;
      background: #d48787;
      border-radius: 32px;
      color: #fff;
      cursor: pointer;
    }
  }
}