
.s-spinner {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

.s-spinner.inline{
  position: relative;
  width: 32px;
  height: 32px;
  left: 10px;
  top: 0;
  margin: 0;
}

@keyframes spinner-bottom {
  0% {
    left: 24px;
  } 10% {
    left: 0;
  } 60% {
    right: 8px;
  } 70% {
    right: 32px;
    left: 0;
  } 100% {
    right: 32px;
    left: 0;
  }
}

@keyframes spinner-left {
  10% {
    top: 32px;
  } 20% {
    top: 8px;
  } 70% {
    bottom: 0;
  } 80% {
    bottom: 24px;
    top: 8px;
  } 100% {
    bottom: 24px;
    top: 8px;
  }
}

@keyframes spinner-top {
  20% {
    right: 24px;
  } 30% {
    right: 0;
  } 80% {
    left: 8px;
  } 90% {
    left: 32px;
    right: 0;
  } 100% {
    left: 32px;
    right: 0;
  }
}

@keyframes spinner-right {
  30% {
    bottom: 32px;
  } 40% {
    bottom: 8px;
  } 90% {
    top: 0;
  } 100% {
    top: 24px;
    bottom: 8px;
  }
}

.s-spinner__left:before,
.s-spinner__left:after,
.s-spinner__right:before,
.s-spinner__right:after {
  content: "";
  position: absolute;
  background-color: #FF3517;
}

.s-spinner__left:before {
  right: 8px;
  bottom: 0;
  left: 0;
  height: 8px;
  animation: spinner-bottom 1.5s linear infinite;
}

.s-spinner__left:after {
  top: 32px;
  bottom: 0;
  left: 0;
  width: 8px;
  animation: spinner-left 1.5s linear infinite;
}

.s-spinner__right:before {
  top: 0;
  right: 24px;
  left: 8px;
  height: 8px;
  animation: spinner-top 1.5s linear infinite;
}

.s-spinner__right:after {
  top: 0;
  bottom: 32px;
  right: 0;
  width: 8px;
  animation: spinner-right 1.5s linear infinite;
}