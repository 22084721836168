.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.user-edit {
    background-color: #cecece;
    margin: 10px 0 !important;
    padding: 10px !important;
}

.edit-role {
    display: flex;
    flex-wrap: wrap;
}

.edit-role-container {
    width: 33%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.trainerMail-btn {
    width: 50px;
    height: 50px;
    font-size: 28px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.trainerMail-btn:active {
    color: #af6767 !important;
}

.trainerMail-btn:hover {
    color: #0081c2 !important;
}

.sub-overview {
    height: 300px;
    overflow: scroll;
}