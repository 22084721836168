.rjsf {
  .field-string {
    width: 100%;
    display: flex;
    padding: 4px 0;
  }

  .control-label {
    width: 120px !important;
  }

  .form-control {
    width: 100%;
  }

  .field-object {
    padding: 4px 0;
  }

  .btn  {
    width: 100%;
    height: 40px;
    background: #ff3517;
    font-size: 22px;
    border: 0;
  }

  .btn:hover {
    background: #ff3617bd;
  }
}

