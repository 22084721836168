.socialMedia {
  padding: 20px;

  .socialMedia-heading {
    border-bottom: 1px solid #0000001f;

    .heading-searchBar {
      display: flex;

      .searchBar-btn {
        margin-top: 12px !important;
        width: 240px !important;
        margin-left: 20px !important;
        color: #fff !important;
        font-weight: bold !important;
      }
      
      label {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }

    .heading-filter {
      display: flex;
      padding: 10px 0;

      .filter-btn {
        color: #fff !important;
        margin: 4px 4px !important;
        padding: 0 10px !important;
        height: 24px !important;
      }

      .filter-btn-disabled {
        background-color: rgb(177, 177, 177) !important;
        color: #4c4c4c !important;
        margin: 4px 4px !important;
        padding: 0 10px !important;
        height: 24px !important;
      }
    
      .filter-text {
        align-items: center;
        flex-direction: row;
        display: flex;
        min-width: 56px;
        font-weight: bold;
        font-size: 18px;
        color: #000000ad;
      }
      
      .flex-column {
        flex-direction: column;
        display: flex;
      }
    }

    .heading-secondFilter {
      margin-left: 54px;
    }
  }

  .socialMedia-content {
    .content-boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content : space-around;
      margin: 0 -8px;
    
      .inner-box {
        padding: 20px 10px;
        margin: 8px;
        width: 400px;
        border: 1px solid #00000014;
        border-radius: 4px;
        cursor: pointer;
        background-color: #f3f3f3;

        .box-matchId {
          position: absolute;
          margin: -18px 0px 0 -8px;
          font-size: 10px;
        }

        .box-info {
          position: absolute;
          margin-left: -10px;
          margin-top: 22px;
          width: 28px;
          opacity: 0.6;

          .info-status {
            background-color: #74cc75;
            color: #404040;
            padding: 5px 10px 5px 5px;
            border-right: 1px solid #949494;
            border-top: 1px solid #cecece;
            border-bottom: 1px solid #949494;
            border-radius: 0 6px 6px 0;
            height: 20px;
            margin-bottom: 2px;

            svg {
              padding: 2px 0px 0px 5px;
            }
          }

          .info-paywall {
            background-color: #7b7b7b70;
            color: #404040;
            padding: 5px 10px 5px 5px;
            border-right: 1px solid #949494;
            border-top: 1px solid #cecece;
            border-bottom: 1px solid #949494;
            border-radius: 0 6px 6px 0;
            margin-bottom: 2px;

            p {
              margin: 2px;
              padding-left: 1px;
              font-weight: bold;
            }

            svg {
              padding: 2px 0px 0px 2px;
            }
          }
        }

        .box-head {
          display: flex;
          justify-content: space-around;
          font-size: 16px;
          margin-bottom: 10px;

          .head-club {
            width: 180px;
            text-align: center;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .club-id {
              font-weight: 100;
              font-size: 14px;
            }
          }
        }

        .box-content {
          display: flex;
          justify-content: space-around;
          padding: 14px 0 30px;
        }

        .box-footer {
          display: flex;
          justify-content: space-around;
          background-color: #ececec;
          border-radius: 6px;
          padding: 6px;
          border: 1px solid #dedede;
          margin: 0 80px;
          color: #2b2b2b;
        }

        /*.box-msgNumber {
          float: right;
          margin-top: -30px;
          background-color: #69cdff;
          color: #fff;
          border-radius: 100px;
          padding: 4px 10px;
          border: 1px solid #e0e0e0;
        }*/

        .box-msgCounter {
          float: right;
          margin-top: -24px;
          padding-right: 8px;
      

          .msgCounter-inner {
            background-color: #69cdff;
            color: #fff;
            border-radius: 100px;
            padding: 4px 10px;
            border: 1px solid #e0e0e0;
            margin-top: -6px;
          }

          .msgCounter-puls {
            color: #fff;
            border-radius: 100px;
            padding: 4px 10px;
            border: 1px solid #e0e0e0;
            margin-top: -6px;
            background-color: #69cdff;
            animation-name: color;
            animation-duration: 3s;
            animation-iteration-count: infinite;
          }

          @keyframes color {
            0% {
              background-color: rgb(255, 79, 79);
            }
            50% {
              background-color: rgb(255, 119, 119);
            }
            100% {
              background-color: rgb(255, 79, 79);
            }
          }
        }
      }
    
      .inner-box:hover {
        background-color: #e0e0e0;
        border-color: #0000003d;

        .box-info {
          opacity: 1;
        }

        .box-footer {
          border: 1px solid #a1a1a1;
        }

        .box-msgCounter {
          .msgCounter-inner {
            background-color: #FF3517;
          }
        }
      }
    }

    .content-moreBtn {
      width: 100%;
      text-align: center;
      padding: 10px 0;

      .btn {
        color: #FF3517  !important;
        font-size: 16px;
        cursor: pointer;
      }

      .btn:hover {
        color: #0086c9  !important;
      }
    }

    .content-newChat {
      padding-bottom: 20px;

      .newChat-topBar {
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          .topBar-title {
            border-bottom-right-radius: 6px;
            font-size: 12px;
            padding-top: 6px;
          }
        }

        .right {
          display: flex;
        }

        .topBar-title {
          background-color: #ececec;
          color: #585858;
          padding: 4px 12px;
          border-bottom-left-radius: 6px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        }

        .topBar-msgCounter {
          background-color: #ececec;
          color: #585858;
          padding: 4px 12px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        }

        .topBar-msgCounterPuls {
          color: #fff;
          border-left: none;
          border-right: none;
          animation-name: color;
          animation-duration: 3s;
          animation-iteration-count: infinite;
        }

        @keyframes msgCounterColor {
          0% {
            background-color: rgb(255, 79, 79);
          }
          50% {
            background-color: rgb(255, 119, 119);
          }
          100% {
            background-color: rgb(255, 79, 79);
          }
        }

        .topBar-btn {
          background-color: #FF3517;
          color: #fff;
          padding: 4px 12px;
          border-bottom-right-radius: 6px;
          cursor: pointer;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        }

        .topBar-btn:hover {
          background-color: #0089ce;
        }

        .topBar-btnDisabled {
          background-color: #ececec;
          color: #585858;
          padding: 4px 12px;
          border-bottom-right-radius: 6px;
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        }
      }

      .newChat-title {
        position: absolute;
        margin-top: -10px;
        margin-left: 10px;
        background-color: #fff;
        padding: 0px 6px;
      }
    }
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    margin: 0 0 20px;
    border-radius: 4px;
  
    li {
      display: inline;
    }
  
    div {
      cursor: pointer;
      position: relative;
      float: left;
      padding: 6px 14px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #FF3517;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  
    div:hover {
      z-index: 3;
      color: #23527c;
      background-color: #eee;
      border-color: #ddd;
    }
  
    .disabled {
      div {
        color: #9e9e9e;
        cursor: default;
      }
    }
  
    .active {
      div {
        background-color: #FF3517;
        color: #fff;
        cursor: default;
      }
    }
  }

  .pagination-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -13px 0 10px;
    font-size: 12px;
  }
}

.socialMedia-sorting {
  margin: 0 0 10px 62px;
  display: flex;
  flex-wrap: wrap;

  .sorting-row {
    display: flex;
    padding-right: 24px;

    .row-text {
      font-size: 14px;
    }
    
    .row-btn {
      cursor: pointer;
      padding-left: 8px;
      color: #717171;
    }

    .row-disable {
      color: #c1c1c1;
    }

    .row-active {
      color: #FF3517;
    }
  }

  .sorting-row:last-child {
    padding-right: 0;
  }
}


.socialMedia-contentContainers {
  .contentContainers-header {
    display: flex;
    align-items: center;
    margin: -18px -18px 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid #b7b7b7;

    .header-text {
      display: flex;
      align-items: center;
      margin-left: 40px;
      color: #949494;
      text-overflow: ellipsis;
      overflow: hidden;

      p {
        margin: 2px 0 0;
        padding: 0 10px;
        font-size: 20px;
        font-weight: bolder;
        white-space: nowrap;
      }
    }
  }

  .contentContainers-content {
    list-style-type: none;
    white-space: nowrap;
    
    .content-infobox {
      background: #fbfbfb;
      border-radius: 6px;
      padding: 10px;
      border: 1px solid #b7b7b7;

      .infobox-table {

        th {
          float: left;
          padding: 4px 0;
        }

        td {
          font-size: 16px;
          padding-left: 14px;
          white-space: normal;
        }
      }
    }

    .content-player {
      background: #fbfbfb;
      border-radius: 6px;
      padding: 10px;
      border: 1px solid #b7b7b7;
    }

    .content-chat {
      background: #fbfbfb;
      border-radius: 6px;
      border: 1px solid #b7b7b7;

      .chat-headbar {
        border-bottom: 1px solid #b7b7b7;
        padding: 2px 12px 2px;
        font-size: 18px;
        font-weight: bold;
        color: #8a8a8a;
      }

      .chat-content {
        min-height: 220px;
        max-height: 400px;
        overflow: overlay;
        padding: 0 14px 10px 10px;

        .content-massage {
          padding: 5px 0;

          .massage-user {
            padding: 0px 6px 2px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;

            .user-date {
              font-size: 10px;
              margin-top: 5px;
            }
          }

          .massage-msg {
            cursor: pointer;
            padding: 6px 12px;
            background: #f2f9fd;
            border-radius: 10px;
            border: 1px solid #b7b7b7;
            font-size: 14px;
            white-space: normal;
          }
        }

        .content-nochatmsg {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #afafaf;
        }
      }

      .chat-content::-webkit-scrollbar {
        width: 4px;
        height: 6px;
      }
      .chat-content::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      .chat-content::-webkit-scrollbar-thumb {
        background: #b1b1b1;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      .chat-content::-webkit-scrollbar-thumb:hover {
        background: #949494;
      }
      .chat-content::-webkit-scrollbar-thumb:active {
        background: #707070;
      }
      .chat-content::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      .chat-content::-webkit-scrollbar-corner {
        background: transparent;
      }
    }

    .content-camera {
      .camera-headtext {
        font-size: 30px;
        font-weight: bold;
        padding: 20px 0;
      }

      th {
        float: left;
        padding: 4px 0;
      }

      td {
        font-size: 16px;
      }

      .camera-ticket {
        display: flex;
        padding-left: 2px;
        padding-bottom: 14px;

        .ticket-btn {
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
          color: rgb(40, 136, 183);
          padding-left: 6px;
          
          a, a:focus, a:visited, a:active {
            color: inherit;
            text-decoration: none;
          }
        }
      }

      .camera-ticket:last-child {
        padding-bottom: 0;
      }
    }

    .content-upload {
      .upload-headtext {
        font-size: 30px;
        font-weight: bold;
        padding: 20px 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .upload-helptext {
        padding-bottom: 20px;

        p {
          padding: 0;
          margin: 2px 0 0;
          font-size: 12px;
        }
      }

      .upload-content {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .upload-graph {
        height: 300px;
      }
    }
  }
}
