
.prevbox {
    background-color: #2d2d39;
}

.prevbox-header {
    
}

.prevbox-content {
    padding: 4px
}

.prevbox-content-time {
    font-weight: 700;
    color: #ccc;
}

.prevbox-content-name {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    line-height: normal;
    margin-bottom: .5rem;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.prevbox-content-desc {
    color: #ccc;
    padding-bottom: 30px;
}

.prevbox-switchBtn {
    position: absolute;
    margin-top: 7px;
    margin-left: 9px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}