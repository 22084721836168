body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff;
  height: 100%;
}

html {
  height: 100%;
}

root {
  height: 100%;
}

.base {
 height: 100%;
}

.mainContent {
  padding-bottom: 40px;
}

.MuiButton-root {
  background: linear-gradient(45deg, #FF3517 30%, #FF3517 90%);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  
}

.btn {
  color: #fff !important;
}
.licenseClaimContainer a{
  color: blue
}