.match-info-headbar {
  padding: 4px 10px;
  background-color: #00acd3;
  color: #fff;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.match-info-bar {
  padding: 2px 10px;
  background-color: rgb(230, 230, 230);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar:hover {
  background-color: rgb(212, 212, 212);
}

.match-info-bar-open {
  padding: 2px 10px;
  background-color: rgb(138, 138, 138);
  margin: 2px 0 0;
  cursor: pointer;
}

.match-info-bar-open:hover {
  background-color: rgb(124, 124, 124);
}


.match-checkclub-bar {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(0);
}

.match-info-bar-match {
  padding: 2px 20px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.match-info-bar-match-detail {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}