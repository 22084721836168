.layout-head {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    font-weight: bold;
    color: #797979;
}

.layout-body {
    padding: 10px;
    word-wrap:break-word;
    text-align: center;
}

.body-infoline {
    padding: 3px;
}

.layout-body a {
    color: #FF3517;
}

.layout-body-video {
    padding: 4px;
    padding-bottom: 0px;
}

.layout-body-video a {
    z-index: 0 !important;
    position: unset !important;
}

.layout-table {
    height: 300px;
    overflow-y: auto;
}

.dialogtableedit-team {
    background-color: #cecece;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #fff;
}

.dialog-btn {
    width: 100%;
}

.test {
    width: 200px;
    height: 500px;
}

.game-btn {
    width: 50%;
}

.game-btn-full {
    width: 100%;
}

.game-btn span {
    font-weight: bold;
    color: #fff;
}

.game-btn-full span {
    font-weight: bold;
    color: #fff;
}

.game-addtest {
    padding: 20px;
}

.addtest-head {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.addtest-headpadding {
    padding: 20px 0 0px;
    color: rgba(0, 0, 0, 0.54);
}

.headpadding-ceck {
    padding: 0 0 0 20px;
}

.Select-control {
    border-color: #fff !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.Select-control:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.623) !important;
    
}

.Select-value {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Select-placeholder {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.addtest-textfield {
    width: 100%;
}

.addtest-btn {
    margin-top: 40px !important;
    margin-right: 20px !important;
}

.flowplayerContainer {
    max-height: 293px !important;
}

.layout-table::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.layout-table::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.layout-table::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.layout-table::-webkit-scrollbar-thumb:active {
  background: #707070;
}
.layout-table::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.layout-table::-webkit-scrollbar-corner {
  background: transparent;
}

.infoline-error {
    color: red;
}

.chips-testadd {
    display: flex;
    flex-wrap: wrap;
}

.formControl-testadd {
    width: 100%;
}

.fromTo {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    align-items: center;
}

.fromTo input {
    width: 70px;
}

.btnClip {
    color: #fff !important;
}