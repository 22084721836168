/* MyReactReader.css */
.fullsize-widget {
    width: 100%; /* Setzt die Breite auf 100% des Elternelements */
    height: 100vh; /* Setzt die Höhe auf 100% des Viewport */
    display: flex; /* Nutzt Flexbox für die Zentrierung des Kind-Elements */
    justify-content: center; /* Zentriert horizontal */
    align-items: center; /* Zentriert vertikal */
  }
  /* MyReactReader.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  padding-top: 50px; /* Abstand von der oberen Kante */
}

.search-input {
  width: 80%;
  max-width: 600px;
  padding: 10px 20px;
  margin-bottom: 30px; /* Abstand zwischen Suchfeld und Query-Boxen */
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  outline: none;
}

.search-input::placeholder {
  color: #aaa;
}

.search-input:focus {
  border-color: #5b9bd5;
}

.query-examples {
  width: 80%;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Erstellt zwei Spalten */
  grid-gap: 20px; /* Abstand zwischen den Boxen */
  margin-bottom: 20px; /* Abstand zum unteren Rand */
  position: relative; /* Erlaubt absolute Positionierung für das p-Element */
  padding-top: 30px; /* Platz für den Hinweistext */
  padding-bottom: 30px; /* Platz für den Hinweistext */
}

.query-button {
  padding: 15px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
  color: #333 !important;
  text-align: left;
}


.validation-notice {
  width: 207%; /* Breite des Hinweises */
  text-align: center; /* Zentrierter Text */
  font-size: 0.9em; /* Schriftgröße des Hinweises */
  color: #6c757d; /* Farbe des Hinweises */
  padding: 10px 0; /* Padding oben und unten */
  background-color: #e9ecef; /* Hintergrundfarbe des Hinweises */
  border-radius: 15px; /* Abgerundete Ecken */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Schatten für Tiefe */
  margin-top: 20px; /* Abstand zwischen den Boxen und dem Hinweistext */
}


.query-button:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

/* Für Mobilgeräte könnten Anpassungen nötig sein */
@media (max-width: 768px) {
  .query-examples {
    grid-template-columns: 1fr; /* Stellt sicher, dass auf kleinen Bildschirmen alles in einer Spalte ist */
  }
  .validation-notice {
    width: 100%; /* Breite des Hinweises */
  }
}

  .grecaptcha-badge { visibility: hidden; }